import {
  getDocumentQAHistory,
  askDocumentQuestion,
  getMultiDocumentQAHistory,
  askMultiDocumentQuestion,
} from "@/modules/api";
import { QA } from "@/model/QA";
import { useQuery, useMutation, useQueryClient} from "@tanstack/react-query";
import { v4 as createUUID } from "uuid";
import { toast } from "react-toastify";
import { QADto } from "@/model/QADto";

export const getDocumentQAHistoryQueryKey = ({ objectiveDocumentId, objectiveId }) => [
  'documentQA',
  objectiveDocumentId ?? objectiveId,
];

/**
 * Single document QA - provide an objectiveDocumentId
 * Multi document QA - provide an objectiveId and documentStates
 */
export const useDocumentQA = ({ objectiveDocumentId, objectiveId, documentStates }) => {
  const queryClient = useQueryClient();
  
  const { data: history, isLoading: loadingHistory } = useQuery({
    queryKey: getDocumentQAHistoryQueryKey({ objectiveDocumentId, objectiveId }),
    queryFn: () => objectiveDocumentId
      ? getDocumentQAHistory({ objectiveDocumentId })
      : getMultiDocumentQAHistory({ objectiveId }),
    enabled: !!objectiveDocumentId || !!objectiveId,
  });

  const { mutate: askQuestion, isLoading: askingQuestion } = useMutation({
    mutationFn: ({ question }) => objectiveDocumentId 
      ? askDocumentQuestion({ objectiveDocumentId, question })
      : askMultiDocumentQuestion({ objectiveId, documentStates, question }),
    onMutate: async ({ question }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      const historyQueryKey = getDocumentQAHistoryQueryKey({ objectiveDocumentId, objectiveId });
      await queryClient.cancelQueries(historyQueryKey);

      // Optimistically add the question to the history query cache.
      const queryBackup = queryClient.getQueryData(historyQueryKey);
      const optimisticId = createUUID();
      if (queryBackup) {
        queryClient.setQueryData(
          historyQueryKey,
          (existing) => [
            ...existing,
            new QADto({
              qa: new QA({
                id: optimisticId,
                question,
                answer: null,
                createdAt: new Date(),
                _isOptimistic: true,
              }),
              sourceCount: 0,
            }),
          ],
        );
      }

      return { optimisticId, historyQueryKey };
    },
    onError: (err, variables, { optimisticId, historyQueryKey }) => {
      queryClient.setQueryData(
        historyQueryKey,
        (existing) => existing?.filter(
          ({ qa }) => qa.id !== optimisticId
        ),
      );
      toast.error("Message failed to send. Please try again.");
    },
    onSettled: (data, error, variables, { historyQueryKey }) => {
      // Refetch the history query to make sure we have the latest data.
      queryClient.invalidateQueries(historyQueryKey);
    },
  })

  return {
    history,
    loadingHistory,
    askQuestion,
    askingQuestion,
  }
};
