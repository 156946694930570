import { axiosInstance as axios } from '@/utils/Axios';
import { Project } from '@/model/Project';
import { Objective } from '@/model/Objective';
import { DocumentWithRank } from '@/model/DocumentWithRank';
import { DocumentCounts } from '@/model/DocumentCounts';
import { QA } from '@/model/QA';
import { UserFeedback } from '@/model/UserFeedback';
import { QAAndSourceDto } from '@/model/QAAndSourceDto';
import { DocumentBBCache } from '@/model/DocumentBBCache';
import { QADto } from '@/model/QADto';
import { ObjectiveDocument } from '@/model/ObjectiveDocument';

export const authenticate = async ({ username, password }) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  const response = await axios.post('/auth/token', formData);
  return response.data;
}

export const getProjects = async () => {
  const response = await axios.get('/projects');
  return response.data.map((project) => new Project(project));
}

export const getProject = async ({ projectId }) => {
  const response = await axios.get(`/projects/${projectId}`);
  return new Project(response.data);
};

export const getObjectivesForProject = async ({ projectId }) => {
  const response = await axios.get(`/projects/${projectId}/objectives`);
  return response.data.map((objective) => new Objective(objective));
};

export const getObjective = async ({ objectiveId }) => {
  const response = await axios.get(`/objectives/${objectiveId}`);
  return new Objective(response.data);
};

export const createObjective = async ({ projectId, description }) => {
  const response = await axios.post(
    '/objectives',
    { project_id: projectId, description },
  );
  return new Objective(response.data);
};

export const updateObjective = async ({ objectiveId, description }) => {
  const response = await axios.put(
    `/objectives/${objectiveId}`,
    { description },
  );
  return new Objective(response.data);
};

export const deleteObjective = async ({ objectiveId }) => {
  await axios.delete(`/objectives/${objectiveId}`);
};

export const getDocumentsForObjective = async ({ objectiveId, documentState }) => {
  const response = await axios.get(`/objective_documents/${objectiveId}/${documentState}`);
  return response.data.map((document) => new DocumentWithRank(document));
};

export const getDocumentCountsForObjective = async ({ objectiveId }) => {
  const response = await axios.get(`/objective_documents/${objectiveId}/document_counts`);
  return new DocumentCounts(response.data);
};

export const updateObjectiveDocumentState = async ({ objectiveDocumentId, documentState, comments }) => {
  const response = await axios.put(
    `/objective_documents/${objectiveDocumentId}/document_state`,
    {
      document_state: documentState,
      comments: comments?.createRequestPayload(),
    },
  );
  return new DocumentWithRank(response.data);
};

export const updateObjectiveDocumentNotes = async ({ objectiveDocumentId, notes }) => {
  const response = await axios.put(
    `/objective_documents/${objectiveDocumentId}/notes`,
    {
      notes: notes,
    },
  );
  return new ObjectiveDocument(response.data);
};

export const undoObjectiveDocumentStateUpdate = async ({ objectiveDocumentId }) => {
  const response = await axios.post(
    `/objective_documents/${objectiveDocumentId}/undo`,
  );
  return new DocumentWithRank(response.data);
};

export const getDocumentQAHistory = async ({ objectiveDocumentId }) => {
  const response = await axios.get(`/qa/${objectiveDocumentId}/history`);
  return response.data.map((data) => new QADto(data));
};

export const askDocumentQuestion = async ({ objectiveDocumentId, question }) => {
  const response = await axios.post(
    `/qa/${objectiveDocumentId}/ask`,
    { question },
  );
  return new QA(response.data);
};

export const getMultiDocumentQAHistory = async ({ objectiveId }) => {
  const response = await axios.get(`/qa/objective/${objectiveId}/history`);
  return response.data.map((data) => new QADto(data));
};

export const askMultiDocumentQuestion = async ({ objectiveId, documentStates, question }) => {
  const response = await axios.post(
    `/qa/objective/${objectiveId}/ask`,
    { in_document_states: documentStates, question },
  );
  return new QA(response.data);
};

export const getDocumentUrl = async ({ documentId }) => {
  const response = await axios.get(`/documents/${documentId}/url`);
  return response.data.url;
};

export const getPredefinedComments = async () => {
  const response = await axios.get("/user_feedback/predefined_comments");
  return response.data;
}

export const createUserFeedback = async ({ userFeedback }) => {
  const response = await axios.post("/user_feedback", userFeedback.createRequestPayload());
  return new UserFeedback(response.data);
}

export const getQASources = async ({ qaId }) => {
  const response = await axios.get(`/qa/${qaId}/sources`);
  return new QAAndSourceDto(response.data);
};

export const getAllQASourceBoundingBoxes = async ({ qaId }) => {
  const response = await axios.get(`/qa/${qaId}/source/bounding_boxes`);
  return response.data.map((bb) => new DocumentBBCache(bb));
};
