import { PageContentPlaceholder } from "@/components/Common/PageContentPlaceholder";
import { RadioButtonGroup } from "@/components/Common/RadioButtonGroup"
import { useProject } from "@/contexts/projectContext";
import { useDocumentCounts } from "@/hooks/useDocumentCounts";
import { DocumentState } from "@/model/DocumentState";
import { useEffect, useState } from "react";
import { MultiDocumentQAPanel } from "./MultiDocumentQAPanel";
import { UploadIcon } from "lucide-react";
import { Loader } from "@/components/Common/Loader";
import { MultiDocumentViewSourcesPanel } from "./MultiDocumentViewSourcesPanel";

const NoDocumentsState = ({ onShowUploadModal }) => (
  <PageContentPlaceholder
    imageSlot={<img src="/empty.svg" alt="No documents" className="h-[300px]" />}
    titleSlot="No documents to query"
    appendSlot={(
      <button
        className="text-blue-400 flex items-center gap-2"
        onClick={onShowUploadModal}
      >
        <UploadIcon />
        Upload Documents
      </button>
    )}
  />
);

export const QueryDocumentsPanel = ({ onShowUploadModal }) => {
  const [
    selectedDocumentState,
    setSelectedDocumentState
  ] = useState(null);
  const [documentStateOptions, setDocumentStateOptions] = useState([]);
  const [viewingSourcesOfQaId, setViewingSourcesOfQaId] = useState(null);

  const { selectedObjective } = useProject();

  const {
    pending,
    approved,
    rejected,
    irrelevant,
    loading: loadingDocumentCounts,
  } = useDocumentCounts({ objectiveId: selectedObjective.id });

  useEffect(() => {
    if (loadingDocumentCounts) return;
    setDocumentStateOptions([
      { label: `Inbox (${pending})`, value: DocumentState.PENDING, disabled: !pending },
      { label: `Accepted (${approved})`, value: DocumentState.APPROVED, disabled: !approved },
      { label: `Rejected (${rejected})`, value: DocumentState.REJECTED, disabled: !rejected },
      { label: `Unrelated (${irrelevant})`, value: DocumentState.IRRELEVANT, disabled: !irrelevant },
    ]);
  }, [loadingDocumentCounts, pending, approved, rejected, irrelevant]);

  if (loadingDocumentCounts) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader />
      </div>
    );
  }

  if (!pending && !approved && !rejected && !irrelevant) {
    return <NoDocumentsState onShowUploadModal={onShowUploadModal} />;
  }

  const showingSources = !!viewingSourcesOfQaId;

  return (
    <div className="grow flex flex-col px-6 pt-6 overflow-hidden">
      <div className="flex flex-col items-center gap-3">
        <div className="text-xl font-semibold text-black">
          Query Documents
        </div>
        <div className="text-sm text-gray-500">
          Select a set of documents to ask questions about:
        </div>
        <RadioButtonGroup
          options={documentStateOptions}
          value={selectedDocumentState}
          onChange={setSelectedDocumentState}
          usePath
        />
      </div>
      <div className="grow flex overflow-hidden justify-center">
        <MultiDocumentQAPanel
          objective={selectedObjective}
          documentStates={[selectedDocumentState]}
          viewingSourcesOfQaId={viewingSourcesOfQaId}
          onViewSources={(qa) => setViewingSourcesOfQaId(qa.id)}
        />
        {showingSources && (
          <MultiDocumentViewSourcesPanel
            selectedQaId={viewingSourcesOfQaId}
            setSelectedQaId={setViewingSourcesOfQaId}
          />
        )}
      </div>
    </div>
  )
};
