import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/globals.css";
import App from "./App";
import { AuthProvider } from "@/contexts/auth";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { TooltipProvider } from "@/components/Common/Tooltip";
import { ProjectProvider } from "@/contexts/projectContext";
import { DocumentUploadProvider } from "./contexts/documentUploadContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 20 // 20 minutes
    },
  },
  queryCache: new QueryCache({ onError: (err) => {
    if (err.response.status === 401) {
      window.location.href = "/logout";
    }
  }}),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <TooltipProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ProjectProvider>
            <DocumentUploadProvider>
              <App />
            </DocumentUploadProvider>
          </ProjectProvider>
        </AuthProvider>
        {/* Make this true if you want dev tools for @tanstack/react-query */}
        {false && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
      </QueryClientProvider>
    </TooltipProvider>
  </React.StrictMode>
);
