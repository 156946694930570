import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { getDocumentUrl } from "@/modules/api";
import { PdfDocument } from "@/components/Common/PdfDocument";
import { Loader } from "../../Common/Loader";

export const DocumentPreview = ({ documentId, supportHighlights, highlights }) => {
  const { data: documentUrl, isLoading, isError } = useQuery({
    queryKey: ["documentUrl", documentId],
    queryFn: () => getDocumentUrl({ documentId }),
  });

  if (isError) {
    return (
      <div className="flex flex-col justify-center items-center h-full gap-6">
        <img className="w-[200px]" src="/no-data.svg" alt="No data" />
        <div className="text-sm">Cannot preview the document, please upload it again</div>
      </div>
    );
  }

  return (
    <div className="grow flex flex-col justify-center items-center w-full overflow-hidden">
      {isLoading && <Loader />}
      {documentUrl && (
        <PdfDocument
          src={documentUrl}
          useBrowser={!supportHighlights}
          highlights={highlights}
        />
      )}
    </div>
  )
};

DocumentPreview.propTypes = {
  documentId: PropTypes.string.isRequired,
  supportHighlights: PropTypes.bool,
};
