import Showdown from "showdown";
import showdownHighlight from "showdown-highlight";

export const markdownToHtmlConverter = new Showdown.Converter({
  extensions: [
    showdownHighlight({
      pre: true,
      auto_detection: true,
    }),
  ],
  tables: true,
});
