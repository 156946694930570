import { ObjectiveInputField } from './ObjectiveInputField';
import { ObjectiveInputFieldType } from './ObjectiveInputFieldType';

export class ObjectiveDescription {
  constructor(data = {}) {
    this.label = data.label;
    this.description = data.description;
    this.taskType = data.task_type;
    this.inputFields = data.input_fields.map((field) => new ObjectiveInputField(field));
    this.initialMessage = data.initial_message;
  }

  hasFileInputField() {
    return this.inputFields.some((field) => field.fieldType === ObjectiveInputFieldType.FILE);
  }
}
