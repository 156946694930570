import { BasePage } from "@/components/Layout/BasePage";
import { useProjectWithPathLookup } from "@/contexts/projectContext";
import { useState } from "react";
import { ReviewWorkflowPanel } from "@/components/Project/ReviewWorkflow/ReviewWorkflowPanel";
import { SelectObjectiveEmptyState } from "@/components/Project/Common/SelectObjectiveEmptyState";
import { ProjectPageHeader } from "@/components/Project/Common/ProjectPageHeader";

export const Project = () => {

  const {
    selectedProject,
    selectedObjective,
    isLoadingSelectedObjective,
  } = useProjectWithPathLookup();
  const [showUploadModal, setShowUploadModal] = useState(false);

  if (!selectedProject || isLoadingSelectedObjective) {
    return null;
  }

  return (
    <BasePage title={
      <ProjectPageHeader
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
      />
    }>
      {/* Placeholder with empty state illustration and dropdown to select/create objective */}
      {!selectedObjective && (
        <SelectObjectiveEmptyState />
      )}
      {/* Review workflow with tabs for the inbox, accepted documents, and rejected documents */}
      {selectedObjective && (
        <ReviewWorkflowPanel
          selectedObjective={selectedObjective}
          onShowUploadModal={() => setShowUploadModal(true)}
        />
      )}
    </BasePage>
  );
};
