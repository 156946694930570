import PropTypes from "prop-types";
import { Project } from "@/model/Project";
import { useProject } from "@/contexts/projectContext";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@/components/Common/Skeleton";
import { Badge } from "@/components/Common/Badge";

export const ProjectCard = ({ project }) => {
  const navigate = useNavigate();
  const { selectedProject, setSelectedProject } = useProject();

  const onClick = () => {
    setSelectedProject(project);
    navigate(`/project/${project.id}`);
  }

  const selected = project && selectedProject?.id === project?.id;

  return (
    <button
      className="text-left bg-white rounded shadow p-6 h-[150px] max-h-[150px] overflow-hidden grid gap-3"
      onClick={onClick}
    >
      <div className="text-base font-medium text-black flex justify-between">
        {project?.name ?? <Skeleton className="w-[250px] h-[24px]" />}
        {selected && <Badge variant="outlined">Selected</Badge>}
      </div>
      <div className="text-xs text-gray-500 overflow-hidden max-h-full">
        {project?.description ?? <Skeleton className="w-[200px] h-[24px]" />}
      </div>
    </button>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.instanceOf(Project),
};
