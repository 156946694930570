import { FeedbackRating } from "./FeedbackRating";

export class FeedbackComments {
  constructor(data = {}) {
    this.freeText = data.free_text ?? data.freeText;
    this.predefinedComments = data.predefined_comments ?? data.predefinedComments;
  }

  createRequestPayload() {
    return {
      free_text: this.freeText,
      predefined_comments: this.predefinedComments,
    }
  }
};

export class DefaultFeedbackBlob {
  constructor(data = {}) {
    this.rating = data.rating;
    this.comments = data.comments && new FeedbackComments(data.comments);
  }

  createRequestPayload() {
    return {
      rating: this.rating,
      comments: this.comments?.createRequestPayload(),
    }
  }
};

export const getFeedbackBlobType = (feedbackTopic) => {
  switch (feedbackTopic) {
    // TODO: Add more feedback topics here (if blob is different)
    default:
      return DefaultFeedbackBlob;
  }
};

export class UserFeedback {
  constructor(data = {}) {
    this.id = data.id;
    this.sourceVersion = data.source_version ?? data.sourceVersion;
    this.userId = data.user_id ?? data.userId;
    this.feedbackTopic = data.feedback_topic ?? data.feedbackTopic;
    this.metadata = data.metadata;
    this.createdAt = new Date(data.created_at ?? data.createdAt);
    this.updatedAt = new Date(data.updated_at ?? data.updatedAt);

    const feedbackBlobType = getFeedbackBlobType(this.feedbackTopic);
    this.feedback = data.feedback && new feedbackBlobType(data.feedback);
  }

  isPositive() {
    return this.feedback.rating?.toLowerCase() === FeedbackRating.THUMBS_UP;
  }

  isNegative() {
    return !this.isPositive();
  }

  createRequestPayload() {
    return {
      feedback_topic: this.feedbackTopic,
      metadata: this.metadata,
      feedback: this.feedback.createRequestPayload(),
    }
  }
};
