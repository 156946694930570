import { QA } from "./QA";
import { QASource } from "./QASource";
import { DocumentWithRank } from "./DocumentWithRank";

export class QASourceAndDocumentWithRankDto {
  constructor(data = {}) {
    this.qaSource = new QASource(data.qa_source ?? data.qaSource);
    this.document = new DocumentWithRank(data.document);
  }
}

export class QAAndSourceDto {
  constructor(data = {}) {
    this.qa = new QA(data.qa);
    this.sources = data.sources.map(
      (source) => new QASourceAndDocumentWithRankDto(source)
    );
  }
}
