import PropTypes from "prop-types";
import { UserFeedback } from "@/model/UserFeedback";
import { cva } from "class-variance-authority";
import { ThumbsUpIcon, ThumbsDownIcon } from "lucide-react";
import { FeedbackRating } from "@/model/FeedbackRating";

export const FeedbackBadge = ({ userFeedback, variant }) => {
  const classFactory = cva(
    // Base styles
    ["flex", "flex-col", "justify-center", "items-center", "w-8", "h-8"],
    {
      variants: {
        // Variant styles
        variant: {
          chip: ["rounded-full", "bg-white", "shadow"],
        },
        rating: {
          [FeedbackRating.THUMBS_UP]: ["text-green-600"],
          [FeedbackRating.THUMBS_DOWN]: ["text-red-600"],
        },
      },
    }
  );

  const rating = userFeedback.feedback.rating;
  const icon = rating === FeedbackRating.THUMBS_UP
    ? <ThumbsUpIcon size="14" />
    : <ThumbsDownIcon size="14" />;

  return (
    <div className={classFactory({ variant, rating })}>
      {icon}
    </div>
  );
};

FeedbackBadge.propTypes = {
  userFeedback: PropTypes.instanceOf(UserFeedback).isRequired,
  variant: PropTypes.oneOf(["chip"]),
};
