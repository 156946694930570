import {
  createContext,
  useReducer,
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef
} from "react";
import { v4 as createUUID } from "uuid";
import {
  sendObjectiveRequestWithFiles,
  sendObjectiveRequest
} from "@/modules/agentApi";
import { ObjectiveDescription } from "@/model/agents/ObjectiveDescription";
import { useAuth } from "./auth";
import { useProject } from "./projectContext";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { DocumentState } from "@/model/DocumentState";
import { getDocumentsQueryKey } from "@/hooks/useDocuments";
import { getDocumentCountsQueryKey } from "@/hooks/useDocumentCounts";

// Actions
export const INIT_UPLOAD = "INIT_UPLOAD";
export const START_UPLOAD = "START_UPLOAD";
export const UPDATE_UPLOAD = "UPDATE_UPLOAD";
export const COMPLETE_UPLOAD = "COMPLETE_UPLOAD";
export const FAILED_UPLOAD = "FAILED_UPLOAD";

const showSuccessToast = (state, filePayload) => {
  const stateOfInteraction = Object.entries(state).reduce((acc, [key, value]) => {
    acc[key] = value.filter(payload => payload.interaction === filePayload.interaction);
    return acc;
  }, {});

  const totalTerminal = (
    stateOfInteraction.completed.length + 
    stateOfInteraction.failed.length
  );
  const total = (
    stateOfInteraction.pending.length +
    stateOfInteraction.uploading.length +
    totalTerminal
  );
  const progressText = `(${totalTerminal + 1}/${total})`;
  toast.success(`Successfully uploaded file: ${filePayload.file.name} ${progressText}`);
};

// Reducer
const uploadReducer = (state, action) => {
  switch (action.type) {
    case INIT_UPLOAD:
      return {
        ...state,
        pending: [...state.pending, action.payload],
      };
    case START_UPLOAD:
      return {
        ...state,
        uploading: [...state.uploading, action.payload],
        pending: state.pending.filter(file => file.id !== action.payload.id),
      };
    case UPDATE_UPLOAD:
      return {
        ...state,
        uploading: state.uploading.map(file =>
          file.id === action.payload.id
            ? { ...file, progress: action.payload.progress }
            : file
        ),
      };
    case COMPLETE_UPLOAD:
      return {
        ...state,
        uploading: state.uploading.filter(file => file.id !== action.payload.id),
        completed: [...state.completed, action.payload],
      };
    case FAILED_UPLOAD:
      return {
        ...state,
        uploading: state.uploading.filter(file => file.id !== action.payload.id),
        failed: [...state.failed, action.payload],
      };
    default:
      throw new Error("Unknown action type");
  }
};

// Context
export const DocumentUploadContext = createContext();

// Provider
export const DocumentUploadProvider = ({ children, maxConcurrent = 3 }) => {
  const [state, dispatch] = useReducer(uploadReducer, {
    pending: [],
    uploading: [],
    completed: [],
    failed: [],
  });

  // Used to get the latest state in the callbacks
  const stateRef = useRef(state);
  stateRef.current = state;

  const [uploadSlots, setUploadSlots] = useState(maxConcurrent);
  const { userId } = useAuth();
  const { selectedProject, selectedObjective } = useProject();
  const queryClient = useQueryClient();

  // Initializes upload for a file
  const initUpload = useCallback(({
    id,
    file,
    interaction,
  }) => {
    const filePayload = {
      id: id ?? createUUID(),
      file,
      interaction: interaction ?? createUUID(),
      progress: 0,
    };
    dispatch({
      type: INIT_UPLOAD,
      payload: filePayload,
    });
    return filePayload.id;
  }, []);

  const triggerDocumentRankRefresh = useCallback(async () => sendObjectiveRequest({
    interaction: createUUID(),
    objectiveDescription: new ObjectiveDescription({
      task_type: "RecommendDocumentTask",
      input_fields: [],
    }),
    inputValues: {
      project_id: selectedProject.id,
      objective_id: selectedObjective.id,
    },
  }), [selectedProject, selectedObjective]);

  // Starts upload for a file
  const startUpload = useCallback(async (filePayload) => {
    const axiosConfig = {
      onUploadProgress: (event) => {
        const progress = Math.round((event.loaded * 100) / event.total);
        dispatch({ type: UPDATE_UPLOAD, payload: { ...filePayload, progress } });
      },
    };

    try {
      const response = await sendObjectiveRequestWithFiles({
        interaction: filePayload.interaction,
        objectiveDescription: {
          taskType: "IndexerUploadTask",
        },
        inputValues: {
          uploader: userId,
          file: filePayload.file,
        },
        axiosConfig,
      });

      showSuccessToast(stateRef.current, filePayload);
      dispatch({ type: COMPLETE_UPLOAD, payload: { ...filePayload, data: response } });

      if (selectedObjective) {
        // Refresh document ranks.
        const response = await triggerDocumentRankRefresh();
        if (response.isError) {
          console.error(`Failed to refresh document ranks: ${response.response}`);
        }

        // Refetch pending documents and counts.
        queryClient.invalidateQueries(getDocumentsQueryKey(
          selectedObjective.id,
          DocumentState.PENDING
        ));
        queryClient.invalidateQueries(getDocumentsQueryKey(
          selectedObjective.id,
          DocumentState.IRRELEVANT
        ));
        queryClient.invalidateQueries(getDocumentCountsQueryKey(selectedObjective.id));
      }
    } 
    catch (e) {
      console.error(e);
      dispatch({ type: FAILED_UPLOAD, payload: { ...filePayload, error: e } });
      toast.error(
        <div>
          Error uploading file: {filePayload.file.name}
          <button
            className="text-blue-400 ml-1"
            onClick={() => initUpload({ ...filePayload, interaction: createUUID() })}
          >
            Retry
          </button>
        </div>,
        { autoClose: false },
      );
    }
  }, [
    userId,
    initUpload,
    triggerDocumentRankRefresh,
    selectedObjective,
    queryClient,
  ]);

  // Checks and starts upload for pending files
  useEffect(() => {
    if (uploadSlots > 0 && state.pending.length > 0) {
      setUploadSlots(prevSlots => prevSlots - 1);
      const nextFilePayload = state.pending[0];
      dispatch({ type: START_UPLOAD, payload: nextFilePayload });
      startUpload(nextFilePayload);
    }
  }, [state.pending, uploadSlots, startUpload]);

  // Reclaims upload slots after a file completes uploading
  useEffect(() => {
    if(state.uploading.length < maxConcurrent) {
      setUploadSlots(prevSlots => prevSlots + 1);
    }
  }, [state.uploading, maxConcurrent]);

  return (
    <DocumentUploadContext.Provider value={{ ...state, initUpload }}>
      {children}
    </DocumentUploadContext.Provider>
  );
};

export const useDocumentUpload = () => {
  const context = useContext(DocumentUploadContext);
  if (context === undefined) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};
