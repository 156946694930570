import { ObjectiveInputFieldType } from './ObjectiveInputFieldType';
import { SelectInputFieldData } from './SelectInputFieldData';

export class ObjectiveInputField {
  constructor(data = {}) {
    this.identifier = data.identifier;
    this.label = data.label;
    this.fieldType = data.field_type;
    this.hideInputValue = data.hide_input_value;
    this.defaultValue = data.default_value;
    this.memorise = data.memorise;

    switch (this.fieldType) {
      case ObjectiveInputFieldType.SELECT:
        this.data = new SelectInputFieldData(data.data);
        break;
      default:
        this.data = data.data;
    }
  }
}
