import PropTypes from "prop-types";
import { Modal } from "@/components/Common/Modal";
import { Button } from "@/components/Common/Button";
import { Checkbox } from "@/components/Common/Checkbox";
import { InputField } from "@/components/Common/InputField";
import { useEffect, useState } from "react";

const PredefinedComment = ({ comment, checked, onCheckedChange }) => {
  const id = `predefined-comment-${comment}`;
  return (
    <div className="flex items-center gap-2">
      <Checkbox 
        id={id}
        checked={checked}
        onCheckedChange={onCheckedChange}
      />
      <label htmlFor={id} className="text-sm cursor-pointer select-none">{comment}</label>
    </div>
  );
};

export const FeedbackModal = ({
  open,
  setOpen,
  title,
  predefinedComments,
  additionalFeedbackPlaceholder,
  onConfirm
}) => {
  const [selectedPredefinedComments, setSelectedPredefinedComments] = useState([]);
  const [freeText, setFreeText] = useState("");

  // Reset state when modal is opened
  useEffect(() => {
    if (open) {
      setSelectedPredefinedComments([]);
      setFreeText("");
    }
  }, [open]);

  const titleSlot = typeof title === "string" ? (
    <div className="text-xl font-medium">{title}</div>
  ) : (
    title
  );
  
  const actionsSlot = (
    <Button
      buttonStyle="primary"
      label="Submit Feedback"
      onClick={() => onConfirm({
        predefinedComments: selectedPredefinedComments,
        freeText,
      })}
    />
  );

  const onCheckedChange = (checked, comment) => {
    if (checked) {
      setSelectedPredefinedComments([
        ...selectedPredefinedComments,
        comment
      ]);
    } else {
      setSelectedPredefinedComments(
        selectedPredefinedComments.filter((c) => c !== comment)
      );
    }
  };

  if (!predefinedComments) return null;

  return (
    <Modal
      open={open}
      onOpenChange={setOpen}
      titleSlot={titleSlot}
      actionsSlot={actionsSlot}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          {predefinedComments.map((comment) => (
            <PredefinedComment
              key={comment}
              comment={comment}
              checked={selectedPredefinedComments.includes(comment)}
              onCheckedChange={(checked) => onCheckedChange(checked, comment)}
            />
          ))}
        </div>
        <InputField
          name="freeText"
          value={freeText}
          onChange={(e) => setFreeText(e.target.value)}
          label="Additional feedback (optional)"
          type="textarea"
          placeholder={additionalFeedbackPlaceholder}
        />
      </div>
    </Modal>
  );
};

FeedbackModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  predefinedComments: PropTypes.arrayOf(PropTypes.string),
  additionalFeedbackPlaceholder: PropTypes.string,
};

FeedbackModal.defaultProps = {
  title: "Feedback",
  additionalFeedbackPlaceholder: "Additional feedback...",
};
