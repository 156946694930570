import PropTypes from "prop-types";
import { Button } from "./Button";

// TODO: Use Modal component
export const DeleteModal = ({ open, onClose, onDelete }) => {
  if (!open) return null;
  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Are you sure?
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete this? This operation cannot be undone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <Button
              label="Delete"
              buttonStyle="error"
              className="w-full inline-flex justify-center border border-transparent px-4 py-2 text-base font-medium focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onDelete}
            />
            <Button
              label="Cancel"
              buttonStyle="outlined"
              className="w-full inline-flex justify-center border border-transparent px-4 py-2 mt-2 text-base font-medium focus:outline-none sm:ml-3 sm:w-auto sm:text-sm sm:mt-0"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
