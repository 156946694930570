import PropTypes from "prop-types";

export const PageContentPlaceholder = ({ imageSlot, titleSlot, appendSlot }) => (
  <div className="grid gap-6 justify-center mt-40">
    <div className="flex justify-center">{imageSlot}</div>
    <div className="grid gap-3">
      <div className="text-2xl font-medium flex justify-center">{titleSlot}</div>
      <div className="text-lg flex justify-center">{appendSlot}</div>
    </div>
  </div>
);

PageContentPlaceholder.propTypes = {
  imageSlot: PropTypes.node.isRequired,
  titleSlot: PropTypes.node.isRequired,
  appendSlot: PropTypes.node.isRequired,
};
