import {
  createHashRouter
} from "react-router-dom";
import {
  FoldersIcon,
  LogOutIcon,
  FileSearch,
  FlagIcon,
  MessagesSquare
} from "lucide-react";

import { NavBar } from "@/components/Common/NavBar";
import { GuardedRoute } from "@/components/Common/GuardedRoute";
import { Login } from "@/pages/Login";
import { Logout } from "@/pages/Logout";
import { Projects } from "@/pages/Projects";
import { Project } from "@/pages/Project";
import { ObjectiveQueryWorkflow } from "@/pages/ObjectiveQueryWorkflow";
import { useProject } from "./contexts/projectContext";

const NavBarWithItems = () => {
  const { selectedProject } = useProject();

  const navItems = [
    {
      path: '/projects',
      icon: FoldersIcon,
      tooltip: 'All Projects',
    },
  ];

  if (selectedProject) {
    navItems.push(...[
      {
        path: `/project/${selectedProject.id}`,
        activePathRegex: /(\/project\/[^/]+$)|(\/project\/[^/]+\/(?!query))/,
        icon: FileSearch,
        tooltip: 'Project: Review Documents',
      },
      {
        path: `/project/${selectedProject.id}/query`,
        activePathRegex: /\/project\/[^/]+\/query/,
        icon: MessagesSquare,
        tooltip: 'Project: Query Documents',
      },
    ]);
  }

  navItems.push(...[
    {
      path: '/logout',
      icon: LogOutIcon,
      tooltip: 'Log Out',
    }
  ]);

  const bottomNavItems = [
    {
      path: 'https://forms.gle/y3u8d61SNqbNEpzE7',
      icon: FlagIcon,
      tooltip: 'Provide Feedback'
    }
  ];

  return (
    <div>
      <NavBar
        navItems={navItems}
        bottomNavItems={bottomNavItems}
      />
    </div>
  );
}

const guardedRoute = ({ path, element, hideNavbar, ...props }) => (
  {
    path,
    element: (
      <GuardedRoute>
        <div className="flex">
          {!hideNavbar && <NavBarWithItems />}
          <div className="grow">
            {element}
          </div>
        </div>
      </GuardedRoute>
    ),
    ...props,
  }
);

export const router = createHashRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  guardedRoute({
    path: "/projects",
    element: <Projects />,
  }),
  guardedRoute({
    path: "/project/:projectId?/:tab?",
    element: <Project />,
  }),
  guardedRoute({
    path: "/project/:projectId/query/:tab?",
    element: <ObjectiveQueryWorkflow />,
  }),
]);
