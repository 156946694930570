import { AgentResponse } from '@/model/agents/AgentResponse';
import { ObjectiveDescription } from '@/model/agents/ObjectiveDescription';
import { axiosInstance as axios } from '@/utils/Axios';

export const getObjectivesForRole = async ({ role }) => {
  const response = await axios.post('/agents/objectives', { role });
  return response.data.objectives.map(
    (objective) => new ObjectiveDescription(objective)
  );
};

export const sendObjectiveRequest = async ({
  interaction,
  objectiveDescription,
  inputValues,
}) => {
  if (objectiveDescription.hasFileInputField()) {
    return await sendObjectiveRequestWithFiles({
      interaction,
      objectiveDescription,
      inputValues,
    });
  }
  const body = {
    interaction,
    task_type: objectiveDescription.taskType,
    task_content: inputValues,
  };
  const result = await axios.post('/agents/objective', body);
  return new AgentResponse(result.data);
};

export const sendObjectiveRequestWithFiles = async ({
  interaction,
  objectiveDescription,
  inputValues,
  axiosConfig = {},
}) => {
  const formData = new FormData();
  formData.append('interaction', interaction);
  formData.append('task_type', objectiveDescription.taskType);
  for (const [key, value] of Object.entries(inputValues)) {
    formData.append(key, value);
  }
  const result = await axios.post('/agents/objective_with_files', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...axiosConfig,
  });
  return new AgentResponse(result.data);
}

export const sendUserResponse = async ({
  interaction,
  agentResponse,
  inputValues,
}) => {
  if (agentResponse.hasFileInFollowUpRequests()) {
    return await sendUserResponseWithFiles({
      interaction,
      agentResponse,
      inputValues,
    });
  }
  const body = {
    interaction,
    destination_task_type: agentResponse.originTaskType,
    responses: [
      {
        request: agentResponse.followUpRequests[0].id,
        input_values: inputValues,
      },
    ],
  };
  const result = await axios.post('/agents/user_response', body);
  return new AgentResponse(result.data);
};

export const sendUserResponseWithFiles = async ({
  interaction,
  agentResponse,
  inputValues,
}) => {
  const formData = new FormData();
  formData.append('interaction', interaction);
  formData.append('destination_task_type', agentResponse.originTaskType);
  formData.append('request', agentResponse.followUpRequests[0].id)
  for (const [key, value] of Object.entries(inputValues)) {
    formData.append(key, value);
  }
  const result = await axios.post('/agents/user_response_with_files', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return new AgentResponse(result.data);
};
