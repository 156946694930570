import PropTypes from "prop-types";
import { cn } from "@/lib/utils";
import { ArrowLeftIcon } from "lucide-react";

export const ViewingSourcesHeader = ({ className, onBack }) => {
  return (
    <div className={cn("flex items-center gap-4", className)}>
      <button
        className={cn(
          "rounded-full",
          "bg-white",
          "drop-shadow-lg",
          "p-2",
          "grid",
          "place-content-center",
        )}
        onClick={onBack}
      >
        <ArrowLeftIcon size="18" />
      </button>
      <h1 className={cn(
        "text-base",
        "font-medium",
        "text-gray-500"
      )}>
        Viewing Sources
      </h1>
    </div>
  )
};

ViewingSourcesHeader.propTypes = {
  className: PropTypes.string,
  onBack: PropTypes.func.isRequired,
};
