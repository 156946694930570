import { UserRequestData } from './UserRequestData';

export class AgentResponse {
  constructor(data = {}) {
    this.id = data.id;
    this.interaction = data.interaction;
    this.isError = data.isError;
    this.response = data.response;
    this.metadata = data.metadata;
    this.originTaskType = data.origin_task_type;
    this.followUpRequests = data.follow_up_requests?.map(
      (request) => new UserRequestData(request)
    );
  }

  hasFileInFollowUpRequests() {
    return this.followUpRequests.some((request) => request.hasFileInput());
  }
}
