import {
  updateObjectiveDocumentNotes,
} from "@/modules/api";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useDocuments } from "./useDocuments";


export const useDocumentNotes = ({ objectiveId, objectiveDocumentId, documentState }) => {
  const { loading: loadingNotes, documents, refetch: refetchDocuments } = useDocuments({ objectiveId, documentState });

  const notes = documents?.find(
    (objectiveDocument) => objectiveDocument.id === objectiveDocumentId
  )?.notes;

  const { mutate: updateNotes, isLoading: updatingNotes } = useMutation({
    mutationFn: ({ notes }) => updateObjectiveDocumentNotes({ objectiveDocumentId, notes }),
    onError: () => toast.error("Updating notes failed. Please try again."),
    onSettled: () => {
      // Refetch the documents to make sure we have the latest data.
      refetchDocuments();
    },
  })

  return {
    notes,
    loadingNotes,
    updateNotes,
    updatingNotes,
  }
};
