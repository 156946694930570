import PropTypes from "prop-types";
import { ProjectCard } from "./ProjectCard";
import { Project } from "@/model/Project";

const EmptyState = () => (
  <div className="flex flex-col items-center gap-10 h-screen p-40">
    <img src="/empty.svg" alt="No projects" className="w-96" />
    <div className="text-2xl text-gray-500">No projects found</div>
  </div>
);

const LoadingState = () => {
  return (
    <>
      {[...Array(6)].map((_, index) => (
        <ProjectCard key={index} />
      ))}
    </>
  )
};

export const ProjectsGrid = ({ projects, loading }) => {
  if (!loading && projects && projects.length === 0) {
    return <EmptyState />;
  }
  return (
    <div className="grid grid-cols-3 gap-3">
      {loading && <LoadingState />}
      {!loading && projects?.map((project) => (
        <ProjectCard key={project.id} project={project} />
      ))}
    </div>
  );
};

ProjectsGrid.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.instanceOf(Project)),
};
