import PropTypes from "prop-types";
import { useDocumentQA } from "@/hooks/useDocumentQA";
import { DocumentQAPanel } from "../Common/DocumentQAPanel";
import { Objective } from "@/model/Objective";
import { DocumentState } from "@/model/DocumentState";
import { useUserFeedback } from "@/hooks/useUserFeedback";
import { cn } from "@/lib/utils";

export const MultiDocumentQAPanel = ({
  objective,
  documentStates,
  viewingSourcesOfQaId,
  onViewSources,
}) => {
  const { 
    history,
    loadingHistory,
    askQuestion,
  } = useDocumentQA({ 
    objectiveId: objective?.id,
    documentStates,
  });

  const {
    getPredefinedComments,
    submitQAFeedback,
  } = useUserFeedback();

  const submitFeedback = (feedback) => {
    submitQAFeedback({
      ...feedback,
      objective,
    });
  }

  return (
    <DocumentQAPanel
      className={cn(!!viewingSourcesOfQaId && "max-w-[450px]")}
      history={history}
      loadingHistory={loadingHistory}
      askQuestion={askQuestion}
      alignBottom
      getPredefinedComments={getPredefinedComments}
      onSaveFeedback={submitFeedback}
      viewingSourcesOfQaId={viewingSourcesOfQaId}
      onViewSources={onViewSources}
    />
  );
};

MultiDocumentQAPanel.propTypes = {
  objective: PropTypes.instanceOf(Objective),
  documentStates: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(DocumentState))
  ),
  viewingSourcesOfQaId: PropTypes.string,
  onViewSources: PropTypes.func,
};
