import { Modal } from "@/components/Common/Modal";
import { Dropzone } from "@/components/Common/Dropzone";
import { Button } from "@/components/Common/Button";
import { useEffect, useState } from "react";
import { TrashIcon } from "lucide-react";
import { useDocumentUpload } from "@/contexts/documentUploadContext";
import { toast } from "react-toastify";
import { v4 as createUUID } from "uuid";

export const UploadDocumentsModal = ({ open, setOpen }) => {
  const [files, setFiles] = useState([]);
  const { initUpload } = useDocumentUpload();

  useEffect(() => {
    if (open) {
      setFiles([]);
    }
  }, [open])

  const onFilesAdded = (files) => {
    setFiles((existing) => [...existing, ...files]);
  };

  const onRemoveFile = (file) => {
    setFiles((files) => files.filter((f) => f.name !== file.name));
  };

  const onUpload = () => {
    toast.info(`Uploading ${files.length} documents, you will be notified when each document is complete.`);
    setOpen(false);

    const interaction = createUUID();
    files.forEach((file, i) => initUpload({ 
      file,
      interaction,
    }));
  };

  return (
    <Modal
      title="Upload Documents"
      actionsSlot={
        <>
          <Button
            label="Cancel"
            buttonStyle="outlined"
            onClick={() => setOpen(false)}
          />
          <Button
            label="Upload"
            buttonStyle="primary"
            onClick={onUpload}
          />
        </>
      }
      open={open}
    >
      <div className="pt-3">
        <Dropzone
          label="Documents (PDF)"
          accept={{
            "application/pdf": [".pdf"],
          }}
          setFiles={onFilesAdded}
        />
      </div>
      {!!files.length && (
        <div className="pt-3 border-t mt-3 grid gap-3">
          {files.map((file) => (
            <div key={file.name} className="flex justify-between text-sm">
              {file.name}
              <button className="ml-2" onClick={() => onRemoveFile(file)}>
                <TrashIcon className="text-red-500" size="16" />
              </button>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
};
