import axios from "axios";

export const axiosInstance = axios.create({
  // baseURL will be "https://dev-cognitive-reviewer-api.a2i2.app" in dev.
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8080",
});

// Detect authentication error and redirect to login page
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && window.location.pathname !== "/") {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);
