import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useState } from "react";
import { Project } from "@/model/Project";
import { Objective } from "@/model/Objective";
import { useAuth } from "./auth";
import { useNavigate, useParams } from "react-router-dom";
import { getObjective, getProject } from "@/modules/api";
import { useQuery } from "@tanstack/react-query";

const ProjectContext = createContext();

export const ProjectProvider = ({ children, project, objective }) => {
  const [selectedProject, setSelectedProject] = useState(project);
  const [selectedObjective, setSelectedObjective] = useState(objective);
  const { userId } = useAuth();

  // Reset the selected project and objective when the user changes
  useEffect(() => {
    if (project) {
      setSelectedProject(project);
    } else {
      setSelectedProject(null);
    }

    if (objective) {
      setSelectedObjective(objective);
    } else {
      setSelectedObjective(null);
    }
  }, [userId, project, objective]);

  return (
    <ProjectContext.Provider value={{
      selectedProject,
      setSelectedProject,
      selectedObjective,
      setSelectedObjective,
    }}>
      {children}
    </ProjectContext.Provider>
  );
};
ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
  project: PropTypes.instanceOf(Project),
  objective: PropTypes.instanceOf(Objective),
};
ProjectProvider.defaultProps = {
  project: null,
  objective: null,
};


export const useProject = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};

/**
 * A hook to persist the selected objective in the localStorage.
 * This is not intended to be used externally.
 */
const useObjectiveSelectionPersistence = ({ context, fetchedProject }) => {
  const [fetchObjectiveId, setFetchObjectiveId] = useState(
    localStorage.getItem(SELECTED_OBJECTIVE_ID_STORAGE_KEY)
  );

  // Query to fetch the objective referenced by the ID in the localStorage.
  const { data: fetchedObjective, isError: objectiveFetchFailed, isLoading } = useQuery({
    queryKey: ["objective", fetchObjectiveId],
    queryFn: () => getObjective({ objectiveId: fetchObjectiveId }),
    // IMPORTANT: Make sure project is loaded before loading this. Otherwise flickering will occur.
    enabled: !!fetchObjectiveId && !!fetchedProject,
    retry: false,
  });

  // Set the objective in the context when it is fetched.
  useEffect(() => {
    const allowObjectiveToBeSelected = (
      fetchedObjective &&
      !context.selectedObjective &&
      context.selectedProject
    );
    if (allowObjectiveToBeSelected) {
      if (context.selectedProject.id === fetchedObjective.projectId) {
        context.setSelectedObjective(fetchedObjective);
      } else {
        // Clear the objective ID in the localStorage if it is not in the project.
        setFetchObjectiveId(null);
        localStorage.removeItem(SELECTED_OBJECTIVE_ID_STORAGE_KEY);
      }
    }
  }, [fetchedObjective, context]);

  // Set the objective ID in the localStorage when it is set in the context.
  useEffect(() => {
    const currentObjectiveId = localStorage.getItem(SELECTED_OBJECTIVE_ID_STORAGE_KEY);
    if (context.selectedObjective && context.selectedObjective.id !== currentObjectiveId) {
      localStorage.setItem(
        SELECTED_OBJECTIVE_ID_STORAGE_KEY,
        context.selectedObjective.id
      );
    }
  }, [context.selectedObjective]);

  // Clear the objective ID in the localStorage if the objective fetch fails.
  useEffect(() => {
    if (objectiveFetchFailed && fetchObjectiveId) {
      setFetchObjectiveId(null);
      localStorage.removeItem(SELECTED_OBJECTIVE_ID_STORAGE_KEY);
    }
  }, [objectiveFetchFailed, fetchObjectiveId]);

  return {
    isLoading: isLoading && !!fetchObjectiveId,
  };
}

/**
 * This hook is used to fetch a project & objective from the API based on the projectId
 * in the URL & objective ID stored in localStorage.
 * 
 * If either are successfully fetched they are set in the project context.
 */
const SELECTED_OBJECTIVE_ID_STORAGE_KEY = "objectiveId";
export const useProjectWithPathLookup = () => {
  const context = useProject();
  const navigate = useNavigate();
  const { projectId } = useParams();

  // Query to fetch the project referenced by the ID in the URL.
  const { data: fetchedProject, isError: projectFetchFailed } = useQuery({
    queryKey: ["project", projectId],
    queryFn: () => getProject({ projectId }),
    enabled: !!projectId,
  });

  // Handle loading the selected objective from localStorage (if it exists)
  const {
    isLoading: isLoadingSelectedObjective
  } = useObjectiveSelectionPersistence({ context, fetchedProject });

  // Set the project in the context when it is fetched.
  useEffect(() => {
    if (fetchedProject && !context.selectedProject) {
      context.setSelectedProject(fetchedProject);
    }
  }, [fetchedProject, context]);


  // Redirect to the projects page if the project fetch fails.
  useEffect(() => {
    if (projectFetchFailed) {
      navigate("/projects");
    }
  }, [projectFetchFailed, navigate]);

  return {
    ...context,
    isLoadingSelectedObjective,
  };
};
