import PropTypes from "prop-types";
import { InputField } from "@/components/Common/InputField";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef } from "react";

export const MessageInput = ({
  messageInput,
  onInputChange,
  onSendMessage,
  loading,
  placeholder,
  disabled,
}) => {
  const inputRef = useRef(null);

  // Focus on input when loading is done
  useEffect(() => {
    if (!loading) {
      inputRef.current?.focus();
    }
  }, [loading]);

  return (
    <div className="w-full flex gap-x-2 relative group/message-input">
      <div className="flex-grow">
        <InputField
          inputRef={inputRef}
          name="messageInput"
          type="textarea"
          rows={5}
          placeholder={placeholder}
          disabled={loading || disabled}
          value={messageInput}
          onChange={onInputChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              onSendMessage();
            }
          }}
        />
      </div>
      <div className="absolute right-4 bottom-6">
        <div className="h-6 w-6">
          <PaperAirplaneIcon
            className={`mt-1 transition ease-in-out ${
              loading
                ? "animate-pulse text-primary-light-3 cursor-not-allowed"
                : "text-gray-400 hover:text-slate-200 cursor-pointer group-focus-within/message-input:text-primary-light"
            }`}
            onClick={() => {
              if (!loading) onSendMessage();
            }}
          />
        </div>
      </div>
    </div>
  );
};

MessageInput.propTypes = {
  messageInput: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  showClearHistory: PropTypes.bool,
};

MessageInput.defaultProps = {
  placeholder: "Send a message...",
};
