import { UserFeedback } from "@/model/UserFeedback";
import {
  createUserFeedback,
  getPredefinedComments as getPredefinedCommentsQuery
} from "@/modules/api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { getDocumentsQueryKey } from "./useDocuments";
import { DocumentWithRank } from "@/model/DocumentWithRank";
import { toast } from "react-toastify";
import { getDocumentQAHistoryQueryKey } from "./useDocumentQA";
import { FeedbackTopic } from "@/model/FeedbackTopic";

export const useUserFeedback = () => {
  const queryClient = useQueryClient();

  // Fetch predefined comments
  const {
    data: predefinedCommentsMap,
    isLoading: loadingPredefinedComments 
  } = useQuery({
    queryKey: ["predefinedComments"],
    queryFn: getPredefinedCommentsQuery,
  });

  // Mutation to submit feedback for a document (e.g. summary feedback / relevance feedback)
  const {
    mutate: submitObjectiveDocumentFeedbackMutation,
  } = useMutation({
    mutationFn: createUserFeedback,
    onMutate: ({ userFeedback, objectiveDocument }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      const queryKey = getDocumentsQueryKey(
        objectiveDocument.objectiveId,
        objectiveDocument.documentState
      );
      queryClient.cancelQueries(queryKey);

      // Optimistically add the user feedback to the document in the query cache
      const queryBackup = queryClient.getQueryData(queryKey);
      if (queryBackup) {
        queryClient.setQueryData(
          queryKey,
          (existingDocs) => existingDocs.map((doc) => {
            if (doc.id === objectiveDocument.id) {
              return new DocumentWithRank({
                ...doc,
                userFeedbacks: [...(doc.userFeedbacks ?? []), userFeedback],
              });
            }
            return doc;
          }) 
        );
      }

      return {
        queryKey,
        queryBackup,
      }
    },
    onSuccess: () => {
      toast.success("Successfully submitted your feedback, thank you!");
    },
    onError: (err, variable, { queryKey, queryBackup }) => {
      // Rollback on error
      queryClient.setQueryData(queryKey, queryBackup);
      toast.error("Failed to submit your feedback, please try again later.");
    },
    onSettled: (data, error, variables, { queryKey }) => {
      // Refetch the documents query to ensure we have the latest data
      queryClient.invalidateQueries(queryKey);
    },
  });

  // Mutation to submit QA feedback
  const {
    mutate: submitQAFeedbackMutation,
  } = useMutation({
    mutationFn: createUserFeedback,
    onMutate: ({ userFeedback, qa, objective, objectiveDocument }) => {
      // Fetch the QA history query key (for either single or multi document QA)
      const queryKey = getDocumentQAHistoryQueryKey({ 
        objectiveDocumentId: objectiveDocument?.id,
        objectiveId: objective?.id
      });

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(queryKey);

      // Optimistically add the user feedback to QA in the query cache
      const queryBackup = queryClient.getQueryData(queryKey);
      if (queryBackup) {
        queryClient.setQueryData(
          queryKey,
          (existingQAs) => existingQAs.map((qaItem) => {
            if (qaItem.qa.id === qa.id) {
              qaItem.userFeedback = new UserFeedback(userFeedback);
            }
            return qaItem;
          })
        );
      }

      return {
        queryKey,
        queryBackup,
      }
    },
    onSuccess: () => {
      toast.success("Successfully submitted your feedback, thank you!");
    },
    onError: (err, variable, { queryKey, queryBackup }) => {
      // Rollback on error
      queryClient.setQueryData(queryKey, queryBackup);
      toast.error("Failed to submit your feedback, please try again later.");
    },
    onSettled: (data, error, variables, { queryKey }) => {
      // Refetch the QA history query to ensure we have the latest data
      queryClient.invalidateQueries(queryKey);
    },
  });

  const submitObjectiveDocumentFeedback = useCallback(({ 
    predefinedComments, 
    freeText, 
    objectiveDocument, 
    rating,
    feedbackTopic,
  }) => {
    const userFeedback = new UserFeedback({
      feedbackTopic,
      metadata: {
        objective_document_id: objectiveDocument.id,
      },
      feedback: {
        rating,
        comments: {
          predefinedComments,
          freeText,
        },
      },
    });
    submitObjectiveDocumentFeedbackMutation({ 
      userFeedback,
      objectiveDocument
    });
  }, [submitObjectiveDocumentFeedbackMutation]);

  /**
   * ======================== Public API ========================
   */
  const submitSummaryFeedback = useCallback(({
    predefinedComments,
    freeText,
    objectiveDocument,
    rating,
  }) => {
    return submitObjectiveDocumentFeedback({
      predefinedComments,
      freeText,
      objectiveDocument,
      rating,
      feedbackTopic: FeedbackTopic.AUTO_SUMMARISATION,
    });
  }, [submitObjectiveDocumentFeedback]);

  const submitDocumentAcceptanceFeedback = useCallback(({
    predefinedComments,
    freeText,
    objectiveDocument,
    rating,
  }) => {
    return submitObjectiveDocumentFeedback({
      predefinedComments,
      freeText,
      objectiveDocument,
      rating,
      feedbackTopic: FeedbackTopic.DOCUMENT_ACCEPTANCE,
    });
  }, [submitObjectiveDocumentFeedback]);

  const submitQAFeedback = useCallback(({
    predefinedComments,
    freeText,
    qa,
    objectiveDocument,
    objective,
    rating,
  }) => {
    const userFeedback = new UserFeedback({
      feedbackTopic: FeedbackTopic.QUERY_RESPONSE,
      metadata: {
        qa_id: qa.id,
      },
      feedback: {
        rating,
        comments: {
          predefinedComments,
          freeText,
        },
      },
    });

    submitQAFeedbackMutation({
      userFeedback,
      qa,
      objectiveDocument,
      objective,
    });
  }, [submitQAFeedbackMutation]);

  const getPredefinedComments = useCallback(({ feedbackTopic, rating }) => {
    return predefinedCommentsMap?.[feedbackTopic]?.[rating];
  }, [predefinedCommentsMap])

  return {
    predefinedCommentsMap: predefinedCommentsMap,
    loadingPredefinedComments,
    getPredefinedComments,
    submitSummaryFeedback,
    submitDocumentAcceptanceFeedback,
    submitQAFeedback,
  };
};
