export class Objective {
  constructor(data = {}) {
    this.id = data.id;
    this.description = data.description;
    this.projectId = data.project_id ?? data.projectId;
    this.createdBy = data.created_by ?? data.createdBy;
    this.createdAt = new Date(data.created_at ?? data.createdAt);
    this.updatedAt = new Date(data.updated_at ?? data.updatedAt);
  }
}
