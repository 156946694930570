import PropTypes from "prop-types";
import { Modal } from "@/components/Common/Modal";
import { Button } from "@/components/Common/Button";
import { Objective } from "@/model/Objective";
import { ShareIcon } from "lucide-react";
import { axiosInstance as axios } from "@/utils/Axios";
import { toast } from "react-toastify";

export const ExportObjectiveDocumentsModal = ({ open, setOpen, objective }) => {
  const onExport = async () => {
    setOpen(false);
    try {
      const response = await axios.get(`/objective_documents/${objective.id}/export_bibtex`, {
        responseType: "blob",
        contentType: "application/x-bibtex",
      });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/x-bibtex" }));
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.setAttribute("download", `export.bib`);
      link.click();
      link.parentNode.removeChild(link);

      toast.success("Documents exported successfully.");
    } catch (e) {
      console.error(e);
      toast.error("An error occurred while exporting the documents.");
    }
  };

  return (
    <Modal
      className="text-base"
      open={open}
      onOpenChange={setOpen}
      title="Export as BibTex"
      actionsSlot={<>
        <Button
          label="Cancel"
          buttonStyle="outlined"
          onClick={() => setOpen(false)}
        />
        <Button
          label={<><ShareIcon size="16" /> Export</>}
          buttonStyle="success"
          onClick={onExport}
        />
      </>}
    >
      <p>Export the approved documents for objective:</p>
      <p className="font-bold mb-4">{objective.description}</p>
      <p>
        A BibTeX file containing each document name, summary, and your notes will be generated.
      </p>
    </Modal>
  )
};

ExportObjectiveDocumentsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  objective: PropTypes.instanceOf(Objective).isRequired,
};
