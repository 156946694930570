import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const useUrlParamAsState = ({ paramName, defaultValue, value, setValue, disabled }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const param = params[paramName];

  useEffect(() => {
    if (disabled) {
      setValue?.(defaultValue);
      return;
    }

    // Set the tab to the current path if it's not already set.
    if (!value && param) {
      setValue(param);
    }

    // Update the path when the tab changes.
    if (value && value !== param) {
      const newPath = location.pathname.replace(`/${param}`, "");
      navigate(`${newPath}/${value}`);
    }

    // Set the tab to the default value if it's not already set.
    if (!value && !param) {
      console.log("setting default value")
      setValue(defaultValue);
    }
  }, [param, value, setValue, disabled, defaultValue, navigate, location.pathname]);

  return value;
};
