import { useUrlParamAsState } from "@/hooks/useUrlParamAsState";
import { cn } from "@/lib/utils";
import { useEffect } from "react";

const RadioButton = ({ label, selected, disabled, onClick }) => {
  return (
    <button 
      className={cn(
        "w-24 p-2 text-xs rounded",
        selected ? 'text-black' : 'text-gray-500',
        selected ? 'bg-white shadow-sm' : 'bg-transparent',
        disabled && 'cursor-not-allowed',
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  )
};

export const RadioButtonGroup = ({ options, value, onChange, usePath }) => {
  const defaultValue = options.find((option) => !option.disabled)?.value;

  useUrlParamAsState({
    paramName: "tab",
    defaultValue: defaultValue,
    value: value,
    setValue: onChange,
    disabled: !usePath,
  });

  useEffect(() => {
    const current = options.find((option) => option.value === value);
    if (current && current.disabled && defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange, options, value]);

  return (
    <div className="flex gap-2 bg-gray-100 p-1 rounded">
      {options.map((option) => (
        <RadioButton
          key={option.value}
          label={option.label}
          selected={option.value === value}
          disabled={option.disabled}
          onClick={() => onChange(option.value)}
        />
      ))}
    </div>
  );
};
