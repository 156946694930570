import { BasePage } from "@/components/Layout/BasePage";
import { ProjectPageHeader } from "@/components/Project/Common/ProjectPageHeader";
import { SelectObjectiveEmptyState } from "@/components/Project/Common/SelectObjectiveEmptyState";
import { QueryDocumentsPanel } from "@/components/Project/QueryWorkflow/QueryDocumentsPanel";
import { useProjectWithPathLookup } from "@/contexts/projectContext";
import { useState } from "react";

export const ObjectiveQueryWorkflow = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const {
    selectedProject,
    selectedObjective,
    isLoadingSelectedObjective
  } = useProjectWithPathLookup();

  if (!selectedProject || isLoadingSelectedObjective) {
    return null;
  }

  return (
    <BasePage title={
      <ProjectPageHeader
        showUploadModal={showUploadModal}
        setShowUploadModal={setShowUploadModal}
      />
    }>
      {!selectedObjective && (
        <SelectObjectiveEmptyState />
      )}
      {selectedObjective && (
        <div className="grow flex flex-col justify-center items-center overflow-hidden">
          <div className="grow w-full flex flex-col overflow-hidden">
            <QueryDocumentsPanel onShowUploadModal={() => setShowUploadModal(true)}/>
          </div>
        </div>
      )}
    </BasePage>
  );
};
