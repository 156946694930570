export class ObjectiveDocument {
  constructor(data = {}) {
    this.id = data.id;
    this.documentId = data.document_id ?? data.documentId;
    this.objectiveId = data.objective_id ?? data.objectiveId;
    this.notes = data.notes;
    this.rank = data.rank;
    this.documentState = data.document_state ?? data.documentState;
    this.createdAt = new Date(data.created_at ?? data.createdAt);
    this.updatedAt = new Date(data.updated_at ?? data.updatedAt);
  }
}
