import { PageContentPlaceholder } from "@/components/Common/PageContentPlaceholder";
import { ObjectiveSelection } from "./ObjectiveSelection";
import { useProject } from "@/contexts/projectContext";

export const SelectObjectiveEmptyState = () => {
  const {
    selectedProject,
    setSelectedObjective,
  } = useProject();

  return (
    <PageContentPlaceholder
      imageSlot={<img className="h-[300px]" src="/void.svg" alt="No objective" />}
      titleSlot="What is your current objective?"
      appendSlot={
        <ObjectiveSelection
          projectId={selectedProject.id}
          setSelectedObjective={setSelectedObjective}
        />
      }
    />
  )
};
