import { useProject } from "@/contexts/projectContext";
import { ObjectiveSelection } from "./ObjectiveSelection";
import { Button } from "@/components/Common/Button";
import { UploadIcon } from "lucide-react";
import { UploadDocumentsModal } from "./UploadDocumentsModal";

export const ProjectPageHeader = ({ showUploadModal, setShowUploadModal }) => {
  const { selectedProject, selectedObjective, setSelectedObjective } = useProject();

  return (
    <div className="flex items-center justify-between">
      <div>{selectedProject.name}</div>
      <div className="flex gap-6">
        {selectedObjective && (
          <ObjectiveSelection
            label="Current Objective:"
            projectId={selectedProject.id}
            selectedObjective={selectedObjective}
            setSelectedObjective={setSelectedObjective}
          />
        )}
        <Button
          label={<><UploadIcon size="16" />Upload Documents</>}
          buttonStyle="primary"
          buttonSize="sm"
          onClick={() => setShowUploadModal(true)}
        />
      </div>
      <UploadDocumentsModal open={showUploadModal} setOpen={setShowUploadModal} />
    </div>
  );
};
