import { ObjectiveInputField } from './ObjectiveInputField';
import { ObjectiveInputFieldType } from './ObjectiveInputFieldType';

export class UserRequestData {
  constructor(data = {}) {
    this.id = data.id;
    this.prompt = data.prompt;
    this.requiredInputs = data.required_inputs.map(
      (field) => new ObjectiveInputField(field)
    );
  }

  hasFileInput() {
    return this.requiredInputs.some((field) => field.fieldType ===  ObjectiveInputFieldType.FILE);
  }
}
