import PropTypes from "prop-types";
import { ThumbsUpIcon, ThumbsDownIcon } from "lucide-react";
import { cva } from "class-variance-authority"

export const FeedbackButtons = ({ prompt, variant, onThumbsUp, onThumbsDown }) => {
  const classFactory = cva(
    // Base styles
    ['flex', 'items-center', 'gap-2', 'px-4', 'py-2'],
    {
      variants: {
        // Variant styles
        variant: {
          chip: ['rounded-full', 'bg-white', 'shadow'],
        }
      }
    }
  );

  const promptClassFactory = cva(
    // Base styles
    ['text-xs', 'text-gray-500'],
    {
      variants: {
        // Variant styles
        variant: {
          chip: ['!text-black'],
        }
      },
    },
  );

  return (
    <div className={classFactory({ variant })}>
      <div className={promptClassFactory({ variant })}>{prompt}</div>
      <button onClick={onThumbsDown} className="text-red-600">
        <ThumbsDownIcon size="12" />
      </button>
      <button onClick={onThumbsUp} className="text-green-600">
        <ThumbsUpIcon size="12" />
      </button>
    </div>
  );
};

FeedbackButtons.propTypes = {
  prompt: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['chip']),
  onThumbsUp: PropTypes.func.isRequired,
  onThumbsDown: PropTypes.func.isRequired,
};

FeedbackButtons.defaultProps = {
  prompt: "Is this correct?",
};
