import PropTypes from "prop-types";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export const Modal = ({
  className,
  title,
  titleSlot,
  children,
  actionsSlot,
  persistent,
  open,
  onOpenChange,
  onKeyPress,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={document.activeElement}
        onKeyPress={onKeyPress}
        onClose={persistent ? () => {} : onOpenChange || (() => {})}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`relative inline-block align-bottom rounded bg-white text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${className}`}
            >
              <div className="bg-white rounded px-4 py-5 sm:px-6 sm:pt-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    {title && (
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    {titleSlot}
                    <div className={`w-full ${(title || titleSlot) && "mt-2"}`}>
                      {children}
                    </div>
                  </div>
                </div>
              </div>
              {actionsSlot && (
                <div className="px-4 py-3 sm:px-6 grid grid-flow-col justify-end gap-2 bg-gray-100 rounded-b">
                  {actionsSlot}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleSlot: PropTypes.any,
  children: PropTypes.any,
  actionsSlot: PropTypes.any,
  persistent: PropTypes.bool,
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  onKeyPress: PropTypes.func,
};

Modal.defaultProps = {
  open: false,
};
