import PropTypes from "prop-types";
import { useViewSourcesController } from "@/hooks/useViewSourcesController"
import { SourceNavigationPanel } from "../Common/ViewSources/SourceNavigationPanel"
import { ViewingSourcesHeader } from "../Common/ViewSources/ViewingSourcesHeader"
import { DocumentPreview } from "../Common/DocumentPreview";
import { useState } from "react";

export const MultiDocumentViewSourcesPanel = ({ selectedQaId, setSelectedQaId }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const {
    selectedSource,
    setSelectedSource,
    qaAndSources,
    boundingBoxesToRender
  } = useViewSourcesController({
    selectedQaId,
    selectedDocument,
    setSelectedDocument
  });

  const onDocumentIdChange = (documentId) => {
    if (selectedDocument?.id === documentId) return;

    const sourceAndDoc = qaAndSources.sources.find((source) => source.document.id === documentId);
    if (!sourceAndDoc) return;

    setSelectedSource(sourceAndDoc.qaSource);
    setSelectedDocument(sourceAndDoc.document);
  };

  return (
    <div className="grow flex flex-col overflow-hidden bg-gray-100 mt-4 border-x border-t">
      <ViewingSourcesHeader className="p-3 border-b" onBack={() => setSelectedQaId(null)} />
      <div className="flex grow overflow-hidden">
        {qaAndSources?.sources && (
          <SourceNavigationPanel
            sources={qaAndSources.sources}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
            onDocumentSelected={onDocumentIdChange}
          />
        )}
        {selectedDocument && (
          <DocumentPreview
            key={selectedDocument?.document.id}
            documentId={selectedDocument?.document.id}
            supportHighlights
            highlights={boundingBoxesToRender}
          />
        )}
      </div>
    </div>
  );
};

MultiDocumentViewSourcesPanel.propTypes = {
  selectedQaId: PropTypes.string,
};
