import { BasePage } from "@/components/Layout/BasePage";
import { ProjectsGrid } from "@/components/Projects/ProjectsGrid";
import { useQuery } from "@tanstack/react-query";
import { getProjects } from "@/modules/api";

export const Projects = () => {
  const { data: projects, isFetching: loading } = useQuery({
    queryFn: getProjects,
    queryKey: ['getProjects'],
    placeholderData: [],
  });

  return (
    <BasePage title="Projects">
      <div className="px-8 py-6 max-w-6xl mx-auto grid gap-6">
        <ProjectsGrid projects={projects} loading={loading} />
      </div>
    </BasePage>
  );
};
