import PropTypes from "prop-types";
import { useEffect } from "react";
import { MessageRow } from "@/components/Message/MessageRow";
import { UserFeedback } from "@/model/UserFeedback";
import "./ChatView.css";
import { cn } from "@/lib/utils";

export const ChatView = ({
  messageHistory,
  loading,
  onViewSources,
}) => {
  const scrollToNewestChat = () => {
    const chatDiv = document.getElementById("chatView");
    chatDiv.scroll({ top: chatDiv.scrollHeight, behavior: "smooth" });
  };

  const scrollToMessage = (messageId) => {
    const chatDiv = document.getElementById("chatView");
    const messageDiv = document.getElementById(`message-${messageId}`);
    chatDiv.scroll({
      top: messageDiv.offsetTop - chatDiv.offsetTop,
      behavior: "smooth",
    });
  };

  const viewingSources = !!messageHistory.find(
    (message) => message.currentlyViewingSources
  );

  useEffect(() => {
    if (viewingSources) {
      const viewingMessage = messageHistory.find(
        (message) => message.currentlyViewingSources
      );
      scrollToMessage(viewingMessage.id);
    } else {
      scrollToNewestChat();
    }
  }, [messageHistory, viewingSources]);


  return (
    <div id="chatView" className="max-h-full overflow-y-auto">
      <div className="flex justify-center">
        <div className="flex flex-col gap-5 flex-1 justify-end max-w-7xl items-center mb-6">
          {messageHistory.map((history, index) => (
            <div
              key={index}
              id={`message-${history.id}`}
              className={cn(
                "w-full",
                // Fade out messages that are not currently being viewed
                viewingSources && !history.currentlyViewingSources && "opacity-30"
              )}
            >
              <MessageRow
                message={history.message}
                userSent={history.userSent}
                userFeedback={history.userFeedback}
                alwaysShowFeedback={index === messageHistory.length - 1}
                disableFeedback={history.disableFeedback}
                onThumbsUp={history.onThumbsUp}
                onThumbsDown={history.onThumbsDown}
                showViewSourcesButton={history.showViewSourcesButton}
                sourceCount={history.sourceCount}
                viewingSources={history.currentlyViewingSources}
                onViewSources={() => onViewSources(history)}
              />
            </div>
          ))}
          {loading && <div className="w-full"><MessageRow loading /></div>}
        </div>
      </div>
    </div>
  );
};

ChatView.propTypes = {
  messageHistory: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.any.isRequired,
      userSent: PropTypes.bool,
      userFeedback: PropTypes.instanceOf(UserFeedback),
      onThumbsUp: PropTypes.func,
      onThumbsDown: PropTypes.func,
    }),
  ),
  loading: PropTypes.bool,
  onViewSources: PropTypes.func,
};
