import PropTypes from "prop-types";
import { useDocumentNotes } from "@/hooks/useDocumentNotes";
import { DocumentNotesPanel } from "./DocumentNotesPanel";
import { Objective } from '@/model/Objective';
import { DocumentWithRank } from "@/model/DocumentWithRank";


export const SingleDocumentNotesPanel = ({ objective, documentWithRank }) => {
  const {
    notes,
    loadingNotes,
    updateNotes,
    updatingNotes,
  } = useDocumentNotes({
    objectiveId: objective.id,
    objectiveDocumentId: documentWithRank?.id,
    documentState: documentWithRank?.documentState,
  });

  return (
    <DocumentNotesPanel
      className="bg-white"
      notes={notes}
      loadingNotes={loadingNotes}
      updateNotes={updateNotes}
      updatingNotes={updatingNotes}
    />
  );
};

SingleDocumentNotesPanel.propTypes = {
  objective: PropTypes.instanceOf(Objective).isRequired,
  documentWithRank: PropTypes.instanceOf(DocumentWithRank),
};
