export class QA {
  constructor(data = {}) {
    this.id = data.id;
    this.question = data.question;
    this.answer = data.answer;
    this.isMultiDoc = data.is_multi_doc ?? data.isMultiDoc;
    this.createdAt = new Date(data.created_at ?? data.createdAt);
    this.updatedAt = new Date(data.updated_at ?? data.updatedAt);

    // Local only
    this._isOptimistic = data._isOptimistic ?? false;
  }
}
