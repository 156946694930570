import {
  getAllQASourceBoundingBoxes,
  getQASources
} from "@/modules/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const useViewSourcesController = ({ selectedQaId, selectedDocument, setSelectedDocument }) => {
  // Instance of QASource that is currently selected.
  const [selectedSource, setSelectedSource] = useState(null);

  // Fetch all of the source records for a given QA.
  const { data: qaAndSources } = useQuery({
    queryKey: ["sources", selectedQaId],
    queryFn: () => getQASources({ qaId: selectedQaId }),
    enabled: !!selectedQaId,
  });

  // Fetch all of the bounding boxes to highlight for a given QA.
  const { data: allQASourceBoundingBoxes } = useQuery({
    queryKey: ["allQASourceBoundingBoxes", selectedQaId],
    queryFn: () => getAllQASourceBoundingBoxes({ qaId: selectedQaId }),
    enabled: !!selectedQaId,
  });

  // Filter the bounding boxes to only those for the currently selected source.
  const boundingBoxesToRender = allQASourceBoundingBoxes?.filter(
    (boundingBox) => boundingBox.pageNumber === selectedSource?.metadata?.page
  );

  // Reset the selected source when the selected QA changes.
  useEffect(() => {
    setSelectedSource(null);
  }, [selectedQaId, setSelectedSource]);

  // Select the first source when the QA and sources are loaded.
  useEffect(() => {
    if (!selectedSource && qaAndSources?.sources?.length > 0) {
      const firstWithMetadata = qaAndSources.sources.find(
        ({ qaSource }) => !!qaSource.metadata
      );
      if (firstWithMetadata) {
        setSelectedSource(firstWithMetadata.qaSource);
        setSelectedDocument?.(firstWithMetadata.document);
      } else {
        setSelectedSource(qaAndSources.sources[0].qaSource);
        setSelectedDocument?.(qaAndSources.sources[0].document);
      }
    }
  }, [qaAndSources?.sources, selectedSource, setSelectedDocument]);

  const setSelectedSourceHandler = (source) => {
    // Change the selected document if the source is from a different document.
    if (source && selectedDocument.id !== source.objectiveDocumentId) {
      const { document } = qaAndSources.sources.find(
        ({ document }) => document.id === source.objectiveDocumentId
      );
      setSelectedDocument(document);
    }
    setSelectedSource(source);
  }

  return {
    selectedSource,
    setSelectedSource: setSelectedSourceHandler,
    qaAndSources,
    boundingBoxesToRender,
  }
}