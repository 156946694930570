import { ObjectiveDocument } from "./ObjectiveDocument";
import { Document } from "./Document";
import { UserFeedback } from "./UserFeedback";
import { FeedbackTopic } from "./FeedbackTopic";

export class DocumentWithRank extends ObjectiveDocument {
  constructor(data = {}) {
    super(data);
    this.documentName = data.document_name ?? data.documentName;
    this.document = new Document(data.document);
    this.userFeedbacks = (data.user_feedbacks ?? data.userFeedbacks)?.map(
      (feedback) => new UserFeedback(feedback)
    );
  }

  get summaryFeedback() {
    return this.userFeedbacks?.find(
      (feedback) => (
        feedback.feedbackTopic === FeedbackTopic.AUTO_SUMMARISATION
      )
    );
  }
}
