import PropTypes from "prop-types";
import { DocumentPreview } from "../Common/DocumentPreview";
import { SourceNavigationPanel } from "../Common/ViewSources/SourceNavigationPanel";
import { DocumentWithRank } from "@/model/DocumentWithRank";
import { ViewingSourcesHeader } from "../Common/ViewSources/ViewingSourcesHeader";
import { useViewSourcesController } from "@/hooks/useViewSourcesController";

export const SingleDocumentViewSourcesPanel = ({ selectedQaId, setSelectedQaId, selectedDocument }) => {
  const {
    selectedSource,
    setSelectedSource,
    qaAndSources,
    boundingBoxesToRender
  } = useViewSourcesController({ selectedQaId, selectedDocument });

  return (
    <div className="col-span-2 flex overflow-hidden">
      <div className="grow flex flex-col overflow-hidden px-8 pb-8 pt-4 gap-4">
        {/* Viewing Sources Header */}
        <ViewingSourcesHeader onBack={() => setSelectedQaId(null)} />
        {/* Document Preview */}
        <DocumentPreview
          documentId={selectedDocument?.document.id}
          supportHighlights
          highlights={boundingBoxesToRender}
        />
      </div>
      {/* Source Navigation Panel */}
      <div className="flex flex-col overflow-hidden border-x">
        {qaAndSources?.sources && (
          <SourceNavigationPanel
            sources={qaAndSources.sources}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
          />
        )}
      </div>
    </div>
  )
};

SingleDocumentViewSourcesPanel.propTypes = {
  selectedQaId: PropTypes.string,
  setSelectedQaId: PropTypes.func,
  selectedDocument: PropTypes.instanceOf(DocumentWithRank),
};
