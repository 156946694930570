import PropTypes from "prop-types";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/Common/Accordion";
import { Badge } from "@/components/Common/Badge";
import { Button } from "@/components/Common/Button";
import { cn } from "@/lib/utils";
import { QASource } from "@/model/QASource";
import { DocumentWithRank } from "@/model/DocumentWithRank";
import { QASourceAndDocumentWithRankDto } from "@/model/QAAndSourceDto";
import { useCallback, useEffect, useState } from "react";

const SourceNavButtons = ({ onPrevious, onNext }) => {
  return (
    <div className="flex justify-between items-center p-3">
      <Button label="Previous" onClick={onPrevious} />
      <Button label="Next" onClick={onNext} />
    </div>
  );
};
SourceNavButtons.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

const SourceItem = ({ source, selected, onClick }) => {
  return (
    <button
      className={cn(
        "border rounded p-2 flex flex-col gap-2 text-left",
        selected ? "bg-primary-50" : "bg-white",
        selected && "border-primary",
      )}
      onClick={onClick}
    >
      <div className="text-sm font-semibold text-gray-600">Page {source.metadata.page + 1}</div>
      <div className="text-xs text-gray-400 line-clamp-3">
        {source.content}
      </div>
    </button>
  )
};
SourceItem.propTypes = {
  source: PropTypes.instanceOf(QASource).isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const SourceList = ({ sources, selectedSource, setSelectedSource }) => {
  if (!sources.length) {
    return (
      <div className="pt-3 px-3 text-sm text-gray-500">
        This document did not contribute to the final answer.
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-3 px-3 pt-3">
      {sources.map((source) => (
        <SourceItem
          key={source.id}
          source={source}
          selected={source.id === selectedSource?.id}
          onClick={() => setSelectedSource(source)}
        />
      ))}
    </div>
  );
};
SourceList.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.instanceOf(QASource)).isRequired,
  selectedSource: PropTypes.instanceOf(QASource),
  setSelectedSource: PropTypes.func.isRequired,
};

const DocumentItem = ({ objectiveDocument, sources, selectedSource, setSelectedSource }) => {
  return (
    <AccordionItem value={objectiveDocument.id}>
      <AccordionTrigger 
        className={cn(
          "text-xs",
          "font-medium",
          "text-gray-500",
          "bg-gray-100",
          "data-[state=open]:bg-primary-light",
          "data-[state=open]:text-white",
          "data-[state=open]:test",
          "p-3",
          "group",
          "overflow-hidden",
          "gap-4"
        )}
        prependChevron={
          <Badge className={cn(
            'group-data-[state=open]:bg-white',
            'group-data-[state=open]:text-primary',
          )}>
            {sources.length}
          </Badge>
        }
      >
        <div className="grow truncate text-left">
          {objectiveDocument.documentName}
        </div>
      </AccordionTrigger>
      <AccordionContent className="bg-gray-50">
        <SourceList
          sources={sources}
          selectedSource={selectedSource}
          setSelectedSource={setSelectedSource}
        />
      </AccordionContent>
    </AccordionItem>
  )
};
DocumentItem.propTypes = {
  objectiveDocument: PropTypes.instanceOf(DocumentWithRank).isRequired,
  sources: PropTypes.arrayOf(PropTypes.instanceOf(QASource)).isRequired,
  selectedSource: PropTypes.instanceOf(QASource),
  setSelectedSource: PropTypes.func.isRequired,
};

export const SourceNavigationPanel = ({ 
  sources,
  selectedSource,
  setSelectedSource,
  onDocumentSelected,
}) => {
  const [selectedDocumentId, _setSelectedDocumentId] = useState(null);

  // Call this to change the selected document
  const handleDocumentChange = useCallback((documentId) => {
    _setSelectedDocumentId(documentId);
    onDocumentSelected?.(documentId);
  }, [onDocumentSelected]);

  useEffect(() => {
    if (selectedSource) {
      handleDocumentChange(selectedSource.objectiveDocumentId);
    }
  }, [selectedSource, handleDocumentChange]);

  // Reduce the List[QASourceAndDocumentWithRankDto] to a Map[UUID, List[QASource]]
  // TODO: Improve the backend to return a format that is easier to consume
  const sourcesByDocId = sources
    .filter(({ qaSource }) => qaSource.metadata)
    .reduce((acc, { document, qaSource }) => {
      const objectiveDocument = document;
      const source = qaSource;
      if (!acc[objectiveDocument.id]) {
        acc[objectiveDocument.id] = {
          objectiveDocument,
          sources: []
        };
      }
      acc[objectiveDocument.id].sources.push(source);
      return acc;
    }, {});

  const navigateSelectedSource = ({ forward }) => {
    const sourceList = Object.values(sourcesByDocId).flatMap(({ sources }) => sources);
    const currentIndex = sourceList.findIndex((source) => source.id === selectedSource.id);
    const nextIndex = forward ? currentIndex + 1 : currentIndex - 1;

    if (nextIndex >= 0 && nextIndex < sourceList.length) {
      const newSourceSelection = sourceList[nextIndex];
      setSelectedSource(newSourceSelection);
    }
  };

  const onNextSource = () => navigateSelectedSource({ forward: true });
  const onPreviousSource = () => navigateSelectedSource({ forward: false });

  const showEmptyState = !Object.keys(sourcesByDocId).length;

  return (
    <div className="grow flex flex-col min-w-[350px] max-w-[350px]">
      <SourceNavButtons
        onNext={onNextSource}
        onPrevious={onPreviousSource}
      />
      <Accordion
        type="single"
        collapsible
        value={selectedDocumentId}
        onValueChange={handleDocumentChange}
      >
        {showEmptyState && (
          <div className="text-sm text-gray-500 p-4">
            The selected answer has no sources. This may happen if something went wrong during the answer generation process.
          </div>
        )}
        {Object.values(sourcesByDocId).map(({ objectiveDocument, sources }) => (
          <DocumentItem
            key={objectiveDocument.id}
            objectiveDocument={objectiveDocument}
            sources={sources.filter((source) => !!source.metadata)}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
          />
        ))}
      </Accordion>
    </div>
  );
};
SourceNavigationPanel.propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.instanceOf(QASourceAndDocumentWithRankDto)
  ).isRequired,
  selectedSource: PropTypes.instanceOf(QASource),
  setSelectedSource: PropTypes.func.isRequired,
  onDocumentSelected: PropTypes.func,
};
