import PropTypes from 'prop-types';
import { DocumentState } from '@/model/DocumentState';
import { DocumentWithRank } from '@/model/DocumentWithRank';
import { Objective } from '@/model/Objective';
import { RankedDocumentList } from './RankedDocumentList';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@/components/Common/Tabs';
import { SingleDocumentNotesPanel } from '../Common/SingleDocumentNotesPanel';
import { SingleDocumentQAPanel } from '../Common/SingleDocumentQAPanel';
import { DocumentPreview } from '../Common/DocumentPreview';
import { Button } from '../../Common/Button';
import { EyeIcon, EyeOffIcon, DownloadIcon } from 'lucide-react';
import { useState } from 'react';
import { cn } from '@/lib/utils';
import { getDocumentUrl } from '@/modules/api';
import { FeedbackModal } from '@/components/Feedback/FeedbackModal';
import { useUserFeedback } from '@/hooks/useUserFeedback';
import { FeedbackRating } from '@/model/FeedbackRating';
import { FeedbackTopic } from '@/model/FeedbackTopic';
import { SingleDocumentViewSourcesPanel } from './SingleDocumentViewSourcesPanel';
import { FeedbackBadge } from '@/components/Feedback/FeedbackBadge';
import { FeedbackButtons } from '@/components/Feedback/FeedbackButtons';
import { CollapsiblePanel } from './CollapsiblePanel';

const ContentSection = ({
  className,
  selectedDocument,
  showDocumentPreview,
  setShowDocumentPreview,
  downloadDocument,
  actionsSlot,
  onFeedback,
}) => {
  const [hideSummary, setHideSummary] = useState(false);
  return (
    <div className={cn("bg-white border-x flex flex-col overflow-hidden", className)}>
      {selectedDocument && (
        <>
          <div className="flex flex-col items-center grow overflow-hidden">
            <div className={cn("flex flex-col items-center gap-3 w-full px-9 border-b py-4", showDocumentPreview && "grow")}>
              <div className="text-xl font-medium max-w-2xl w-full flex flex-col gap-3">
                {selectedDocument.documentName}
                <div className="flex gap-3">
                  <Button
                    buttonSize="sm"
                    label={
                      !showDocumentPreview 
                        ? (<><EyeIcon size="14" /> Preview</>)
                        : (<><EyeOffIcon size="14" /> Hide Preview</>)
                    }
                    onClick={() => setShowDocumentPreview(!showDocumentPreview)}
                  />
                  <Button
                    label={<><DownloadIcon size="14" /> Download</>}
                    buttonSize="sm"
                    onClick={() => downloadDocument(selectedDocument)}
                  />
                </div>
              </div>
              {showDocumentPreview && (
                <DocumentPreview
                  documentId={selectedDocument.document.id}
                />
              )}
            </div>
            <CollapsiblePanel
              side="top"
              className={cn(
                "w-full",
                showDocumentPreview && "max-h-[20vh]",
                !showDocumentPreview && "grow"
              )}
              isCollapsed={hideSummary}
              setIsCollapsed={setHideSummary}
              disabled={!showDocumentPreview}
            >
              <div className="grow px-9 w-full flex justify-center overflow-auto">
                <div className="flex flex-col gap-3 py-4 max-w-2xl w-full">
                  <div className="flex justify-between items-center">
                    <div className="text-base font-medium">Summary</div>
                      {selectedDocument.summaryFeedback ? (
                        <FeedbackBadge userFeedback={selectedDocument.summaryFeedback} />
                      ) : (
                        <FeedbackButtons
                          onThumbsUp={() => onFeedback(true)}
                          onThumbsDown={() => onFeedback(false)}
                        />
                      )}
                    </div>
                  <div className="text-sm text-gray-500 pb-4">{selectedDocument.document.summary || 'No summary generated.'}</div>
                </div>
              </div>
            </CollapsiblePanel>
            <div className="border-t w-full py-3 flex justify-center gap-3">
              {actionsSlot}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const BaseReviewWorkflowPanelTab = ({
  objective,
  documentState,
  selectedDocument,
  setSelectedDocument,
  actionsSlot
}) => {
  const [showDocumentPreview, setShowDocumentPreview] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackModalData, setFeedbackModalData] = useState({});
  const [showSourcesOfQaId, setShowSourcesOfQaId] = useState(null);
  const [hideRankedDocumentList, setHideRankedDocumentList] = useState(false);
  const [hideQaPanel, setHideQaPanel] = useState(false);

  const {
    getPredefinedComments,
    submitSummaryFeedback,
  } = useUserFeedback();

  const downloadDocument = async ({ document: doc }) => {
    const url = await getDocumentUrl({ documentId: doc.id });
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onFeedback = (isPositive) => {
    setShowFeedbackModal(true);

    const rating = isPositive 
      ? FeedbackRating.THUMBS_UP
      : FeedbackRating.THUMBS_DOWN;
    setFeedbackModalData({
      rating,
      predefinedComments: getPredefinedComments({
        rating,
        feedbackTopic: FeedbackTopic.AUTO_SUMMARISATION,
      })
    });
  };

  const onSubmitFeedback = (feedbackData) => {
    setShowFeedbackModal(false);

    const { rating } = feedbackModalData;
    submitSummaryFeedback({
      ...feedbackData,
      objectiveDocument: selectedDocument,
      rating,
    });
  };

  const showPositiveRatingInModal = feedbackModalData.rating === FeedbackRating.THUMBS_UP;
  const showSources = !!showSourcesOfQaId;

  const renderingThreePanels = !hideRankedDocumentList && !hideQaPanel;
  const renderingLeftPanelOnly = !hideRankedDocumentList && hideQaPanel;
  const renderingRightPanelOnly = hideRankedDocumentList && !hideQaPanel;
  const renderingSinglePanel = hideRankedDocumentList && hideQaPanel;

  return (
    <div className={cn(
      "grid h-full",
      renderingThreePanels && "grid-cols-[1fr_2fr_1fr]",
      renderingRightPanelOnly && "grid-cols-[40px_2fr_1fr]",
      renderingLeftPanelOnly && "grid-cols-[1fr_2fr_40px]",
      renderingSinglePanel && "grid-cols-[40px_2fr_40px]",
    )}>
      {!showSources && (
        <>
          {/* Document List Section */}
          <CollapsiblePanel
            side="right"
            isCollapsed={hideRankedDocumentList}
            setIsCollapsed={setHideRankedDocumentList}
          >
            <RankedDocumentList
              objectiveId={objective.id}
              documentState={documentState}
              selectedDocument={selectedDocument}
              onDocumentSelected={setSelectedDocument}
            />
          </CollapsiblePanel>
          <ContentSection
            selectedDocument={selectedDocument}
            showDocumentPreview={showDocumentPreview}
            setShowDocumentPreview={setShowDocumentPreview}
            downloadDocument={downloadDocument}
            actionsSlot={actionsSlot}
            onFeedback={onFeedback}
          />
        </>
      )}

      {showSources && (
        <SingleDocumentViewSourcesPanel
          selectedDocument={selectedDocument}
          selectedQaId={showSourcesOfQaId}
          setSelectedQaId={setShowSourcesOfQaId}
        />
      )}

      {/* Document QA / Notes Section */}
      <CollapsiblePanel
        side="left"
        isCollapsed={hideQaPanel}
        setIsCollapsed={setHideQaPanel}
      >
        <Tabs defaultValue="questions" className="grow flex flex-col overflow-hidden">
          <TabsList className="border-b px-9">
            <TabsTrigger value="questions">Questions</TabsTrigger>
            <TabsTrigger value="notes">Notes</TabsTrigger>
          </TabsList>
          <TabsContent value="questions" className="flex flex-col grow overflow-hidden">
            <SingleDocumentQAPanel
              key={selectedDocument?.id}
              objectiveDocument={selectedDocument}
              viewingSourcesOfQaId={showSourcesOfQaId}
              onViewSources={(qa) => setShowSourcesOfQaId(qa.id)}
            />
          </TabsContent>
          <TabsContent value="notes" className="flex flex-col grow overflow-hidden">
            <SingleDocumentNotesPanel
              key={selectedDocument?.id}
              objective={objective}
              documentWithRank={selectedDocument}
            />
          </TabsContent>
        </Tabs>
      </CollapsiblePanel>

      {/* Summary User Feedback Modal */}
      <FeedbackModal
        open={showFeedbackModal}
        setOpen={setShowFeedbackModal}
        title={showPositiveRatingInModal
          ? 'What did you like about the summary?'
          : 'What was the issue with the summary?'
        }
        predefinedComments={feedbackModalData.predefinedComments}
        additionalFeedbackPlaceholder={showPositiveRatingInModal
          ? 'What else did you like about the summary?'
          : 'What else was the issue with the summary?'
        }
        onConfirm={onSubmitFeedback}
      />
    </div>
  );
};

BaseReviewWorkflowPanelTab.propTypes = {
  objective: PropTypes.instanceOf(Objective).isRequired,
  documentState: PropTypes.oneOf(
    Object.values(DocumentState)
  ).isRequired,
  selectedDocument: PropTypes.instanceOf(DocumentWithRank),
  setSelectedDocument: PropTypes.func.isRequired,
  actionsSlot: PropTypes.node,
};
