export class QASource {
  constructor(data = {}) {
    this.id = data.id;
    this.qaId = data.qa_id ?? data.qaId;
    this.objectiveDocumentId = data.objective_document_id ?? data.objectiveDocumentId;
    this.content = data.content;
    this.metadata = data.metadata;
    this.createdAt = new Date(data.created_at ?? data.createdAt);
    this.updatedAt = new Date(data.updated_at ?? data.updatedAt);
  }
}
