import PropTypes from "prop-types";
import { useEffect } from "react";

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';

import { DocumentBBCache } from "@/model/DocumentBBCache";

const BrowserBasedRenderer = ({ src }) => {
  const frameStyle = {
    width: 100 + '%',
    height: 100 + '%',
  };
  return (
    <object data={src} style={frameStyle}>
      Failed to load PDF
    </object>
  );
};

const Highlight = ({ cssProperties }) => {
  // Attempt to reduce overlap between other highlights.
  const paddingPct = -0.060;
  const transformedCssProperties = {
    ...cssProperties,
    left: `calc(${cssProperties.left} - ${paddingPct}%)`,
    top: `calc(${cssProperties.top} - ${paddingPct}%)`,
    height: `calc(${cssProperties.height} + ${paddingPct * 2}%)`,
    width: `calc(${cssProperties.width} + ${paddingPct * 2}%)`,
  };
  return (
    <div
      className="bg-blue-400/30 z-10"
      style={transformedCssProperties}
    />
  );
};

const renderHighlights = (props, highlights) => {
  return  (
    <div>
        {highlights && highlights
          .filter((highlight) => highlight.pageNumber === props.pageIndex)
          .map((highlight, idx) => (
            <Highlight
              key={idx}
              cssProperties={props.getCssProperties({
                left: highlight.leftCoord,
                top: highlight.topCoord,
                width: highlight.width,
                height: highlight.height,
                pageIndex: props.pageIndex,
              })}
            />
          ))
        }
    </div>
  );
};

const RendererWithHighlighSupport = ({ src, highlights }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const highlightPluginInstance = highlightPlugin({
    renderHighlights: (props) => renderHighlights(props, highlights),
    trigger: Trigger.None,
  });

  const navigationPlugin = defaultLayoutPluginInstance
    .toolbarPluginInstance
    .pageNavigationPluginInstance;

  // Jump to the first highlight when highlights are loaded.
  useEffect(() => {
    if (highlights) {
      const firstHighlight = highlights[0];
      if (firstHighlight) {
        navigationPlugin.jumpToPage(firstHighlight.pageNumber);
      }
    }
  }, [highlights, navigationPlugin]);

  const plugins = [
    defaultLayoutPluginInstance,
    highlightPluginInstance,
  ];

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
      <div className="w-full grow overflow-hidden">
        <Viewer
          fileUrl={src}
          plugins={plugins}
        />
      </div>
    </Worker>
  );
};

export const PdfDocument = ({ src, useBrowser, highlights }) => {
  return useBrowser ? (
    <BrowserBasedRenderer src={src} />
  ) : (
    <RendererWithHighlighSupport
      src={src}
      highlights={highlights}
    />
  );
};

PdfDocument.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.number,
  useBrowser: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.instanceOf(DocumentBBCache)),
};
