export class DocumentBBCache {
  constructor(data = {}) {
    this.id = data.id;
    this.documentId = data.document_id ?? data.documentId;
    this.content = data.content;
    this.pageNumber = data.page_number ?? data.pageNumber;
    this.leftCoord=  data.left_coord ?? data.leftCoord;
    this.topCoord = data.top_coord ?? data.topCoord;
    this.width = data.width;
    this.height = data.height;
  }
}
