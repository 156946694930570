import PropTypes from "prop-types";
import { useDocumentQA } from "@/hooks/useDocumentQA";
import { DocumentQAPanel } from "./DocumentQAPanel";
import { ObjectiveDocument } from "@/model/ObjectiveDocument";
import { useUserFeedback } from "@/hooks/useUserFeedback";

export const SingleDocumentQAPanel = ({ objectiveDocument, viewingSourcesOfQaId, onViewSources }) => {
  const { 
    history,
    loadingHistory,
    askQuestion,
  } = useDocumentQA({ 
    objectiveDocumentId: objectiveDocument?.id,
  });

  const {
    getPredefinedComments,
    submitQAFeedback,
  } = useUserFeedback();

  const submitFeedback = (feedback) => {
    submitQAFeedback({
      ...feedback,
      objectiveDocument,
    });
  }

  return (
    <DocumentQAPanel
      className="bg-white"
      history={history}
      loadingHistory={loadingHistory}
      askQuestion={askQuestion}
      getPredefinedComments={getPredefinedComments}
      onSaveFeedback={submitFeedback}
      viewingSourcesOfQaId={viewingSourcesOfQaId}
      onViewSources={onViewSources}
    />
  );
};

SingleDocumentQAPanel.propTypes = {
  objectiveDocument: PropTypes.instanceOf(ObjectiveDocument),
  viewingSourcesOfQaId: PropTypes.string,
  onViewSources: PropTypes.func,
};
