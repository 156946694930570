import { useQuery } from '@tanstack/react-query';
import { getDocumentCountsForObjective } from '@/modules/api';

export const getDocumentCountsQueryKey = (objectiveId) => ['documentCounts', objectiveId];

export const useDocumentCounts = ({ objectiveId }) => {
  const { data: counts, isLoading: loading } = useQuery({
    queryFn: () => getDocumentCountsForObjective({ objectiveId }),
    queryKey: getDocumentCountsQueryKey(objectiveId),
    enabled: !!objectiveId,
  });

  return {
    ...counts,
    loading,
  };
};
