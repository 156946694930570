import { QA } from "./QA";
import { UserFeedback } from "./UserFeedback";

export class QADto {
  constructor(data = {}) {
    this.qa = new QA(data.qa);
    this.sourceCount = data.source_count ?? data.sourceCount;

    const userFeedbackData = data.user_feedback ?? data.userFeedback;
    this.userFeedback = userFeedbackData && new UserFeedback(userFeedbackData);
  }
}
