import PropTypes from "prop-types";
import "highlight.js/styles/github.css";
import { markdownToHtmlConverter } from "@/utils/MarkdownParser";
import { FeedbackButtons } from "../Feedback/FeedbackButtons";
import { FeedbackBadge } from "../Feedback/FeedbackBadge";
import { UserFeedback } from "@/model/UserFeedback";
import { FilesIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipTrigger, TooltipContent } from "../Common/Tooltip";

const ViewSourcesButton = ({ className, sourceCount, disabled, onClick }) => (
  <button
    className={cn("text-sm text-blue-500 flex gap-1 items-center mt-2", className)}
    disabled={disabled}
    onClick={onClick}
  >
    <FilesIcon size="14" />
    View Sources ({sourceCount})
  </button>
);

export const MessageRow = ({
  userSent,
  message,
  loading,
  hideAvatar,
  disableFeedback,
  userFeedback,
  alwaysShowFeedback,
  onThumbsUp,
  onThumbsDown,
  showViewSourcesButton,
  sourceCount,
  viewingSources,
  onViewSources,
}) => {
  const textColour = userSent ? "text-slate-50" : "text-slate-900";
  const bgColour = userSent ? "bg-primary" : "bg-gray-200";
  const messageClassName = cn(
    'flex-1 text-left px-3 py-2 whitespace-pre-line rounded text-sm leading-[175%]',
    bgColour,
    viewingSources && "border border-primary",
  );

  const html = {
    __html: markdownToHtmlConverter.makeHtml(
      typeof message === "string" ? message : ""
    ),
  };

  return (
    <div className={`flex gap-x-2 ${textColour}`}>
      {!hideAvatar && (
        <div
          className={`mt-2 text-xs rounded-full w-7 h-7 min-w-[1.75rem] min-h-[1.75rem] flex items-center justify-center sticky top-0 ${bgColour}`}
        >
          {userSent ? "ME" : "AI"}
        </div>
      )}

      <div className={`${messageClassName} relative group/message`}>
        {loading && !userSent ? (
          <div className="sk-flow">
            <div className="sk-flow-dot"></div>
            <div className="sk-flow-dot"></div>
            <div className="sk-flow-dot"></div>
          </div>
        ) : userSent ? (
          <div>{message}</div>
        ) : (
          <>
            <div id="renderedMarkdown" dangerouslySetInnerHTML={html}></div>
            {showViewSourcesButton && !viewingSources && (
              <>
                {sourceCount > 0 ? (
                  <ViewSourcesButton
                    className={cn(!alwaysShowFeedback ? "group-hover/message:mb-3" : "mb-3")}
                    sourceCount={sourceCount}
                    onClick={onViewSources}
                  />
                ) : (
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <div>
                        <ViewSourcesButton sourceCount={0} disabled />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="bottom">Something went wrong, no documents were used to generate the response</TooltipContent>
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}

        {!userSent && !loading && !disableFeedback && (
          <div className={cn(
            !alwaysShowFeedback && "group-hover/message:block hidden",
            "absolute right-0 -bottom-4"
          )}>
            {userFeedback ? (
              <FeedbackBadge
                userFeedback={userFeedback}
                variant="chip"
              />
            ) : (
              <FeedbackButtons
                variant="chip"
                onThumbsUp={onThumbsUp}
                onThumbsDown={onThumbsDown}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

MessageRow.propTypes = {
  userSent: PropTypes.bool,
  message: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  disableFeedback: PropTypes.bool,
  userFeedback: PropTypes.instanceOf(UserFeedback),
  alwaysShowFeedback: PropTypes.bool,
  onThumbsUp: PropTypes.func,
  onThumbsDown: PropTypes.func,
  showViewSourcesButton: PropTypes.bool,
  sourceCount: PropTypes.number,
  viewingSources: PropTypes.bool,
  onViewSources: PropTypes.func,
};

MessageRow.defaultProps = {
  userSent: false,
  message: "",
  loading: false,
  hideAvatar: false,
  disableFeedback: false,
  alwaysShowFeedback: false,
};
