import PropTypes from "prop-types";
import { useState } from "react";
import { Objective } from "@/model/Objective";
import { PageContentPlaceholder } from "@/components/Common/PageContentPlaceholder";
import { useDocuments } from "@/hooks/useDocuments";
import { DocumentState } from "@/model/DocumentState";
import { BaseReviewWorkflowPanelTab } from "./BaseReviewWorkflowPanelTab";
import { UndoIcon } from "lucide-react";
import { Button } from "@/components/Common/Button";
import { Loader } from "@/components/Common/Loader";

const EmptyState = () => (
  <PageContentPlaceholder
    imageSlot={<img src="empty.svg" alt="No documents" className="h-[300px]" />}
    titleSlot="No approved documents"
    appendSlot="Documents will appear here once they are approved by you."
  />
)

export const ReviewWorkflowPanelAccepted = ({ objective }) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const { documents, loading, resetDocument } = useDocuments({
    objectiveId: objective.id,
    documentState: DocumentState.APPROVED,
  });

  if (!documents?.length && !loading) {
    return <EmptyState />;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader />
      </div>
    );
  }

  const selectedDocument = documents.find(({ id }) => id === selectedDocumentId);
  const setSelectedDocument = (document) => setSelectedDocumentId(document?.id);

  return (
    <BaseReviewWorkflowPanelTab
      objective={objective}
      selectedDocument={selectedDocument}
      setSelectedDocument={setSelectedDocument}
      documentState={DocumentState.APPROVED}
      actionsSlot={
        <Button
          buttonStyle="error"
          label={<><UndoIcon size="16" /> Undo approval</>}
          onClick={() => resetDocument(selectedDocument)}
        />
      }
    />
  );
};

ReviewWorkflowPanelAccepted.propTypes = {
  objective: PropTypes.instanceOf(Objective).isRequired,
};
