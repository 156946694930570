import PropTypes from 'prop-types';
import './Loader.css';

export const Loader = ({ light }) => (
  <div className={`sk-grid loader ${light ? 'light' : ''}`}>
    <div className="sk-grid-cube"></div>
    <div className="sk-grid-cube"></div>
    <div className="sk-grid-cube"></div>
    <div className="sk-grid-cube"></div>
    <div className="sk-grid-cube"></div>
    <div className="sk-grid-cube"></div>
    <div className="sk-grid-cube"></div>
    <div className="sk-grid-cube"></div>
    <div className="sk-grid-cube"></div>
  </div>
);

Loader.propTypes = {
  light: PropTypes.bool,
};

Loader.defaultProps = {
  light: false,
};
