import PropTypes from "prop-types";
import { useState } from "react";
import { Objective } from "@/model/Objective";
import { PageContentPlaceholder } from "@/components/Common/PageContentPlaceholder";
import { useDocuments } from "@/hooks/useDocuments";
import { DocumentState } from "@/model/DocumentState";
import { BaseReviewWorkflowPanelTab } from "./BaseReviewWorkflowPanelTab";
import { CheckIcon, XIcon } from "lucide-react";
import { Button } from "@/components/Common/Button";
import { Loader } from "@/components/Common/Loader";

const EmptyState = () => (
  <PageContentPlaceholder
    imageSlot={<img src="empty.svg" alt="No documents" className="h-[300px]" />}
    titleSlot="No irrelevant documents"
    appendSlot="Documents automatically detected as irrelevant to your objective will appear here."
  />
)

export const ReviewWorkflowPanelIrrelevant = ({ objective }) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const { documents, loading, approveDocument, rejectDocument } = useDocuments({
    objectiveId: objective.id,
    documentState: DocumentState.IRRELEVANT,
  });

  if (!documents?.length && !loading) {
    return <EmptyState />;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader />
      </div>
    );
  }

  const selectedDocument = documents.find(({ id }) => id === selectedDocumentId);
  const setSelectedDocument = (document) => setSelectedDocumentId(document?.id);

  return (
    <BaseReviewWorkflowPanelTab
      objective={objective}
      selectedDocument={selectedDocument}
      setSelectedDocument={setSelectedDocument}
      documentState={DocumentState.IRRELEVANT}
      actionsSlot={
        <>
          <Button
            label={<><CheckIcon size="16" /> Accept</>}
            buttonStyle="success"
            onClick={() => approveDocument(selectedDocument)}
          />
          <Button
            label={<><XIcon size="16" /> Reject</>}
            buttonStyle="error"
            onClick={() => rejectDocument(selectedDocument)}
          />
      </>
      }
    />
  );
};

ReviewWorkflowPanelIrrelevant.propTypes = {
  objective: PropTypes.instanceOf(Objective).isRequired,
};
