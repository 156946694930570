import PropTypes from "prop-types";

export const InputField = ({
  label,
  name,
  type,
  value,
  rows,
  onChange,
  placeholder,
  error,
  className,
  inputClassName,
  icon,
  disabled,
  inputRef,
  ...rest
}) => {
  const textarea = type === "textarea";
  const resolvedInputClassName = [
    "w-full",
    "text-black bg-gray-100 disabled:opacity-100 disabled:cursor-not-allowed",
    "text-base",
    "rounded",
    "border border-gray-300 rounded",
    "outline outline-transparent",
    "outline-offset-0",
    "py-3",
    "transition ease-in-out",
    "focus:border-primary",
    error ? "border-red-500" : "",
    icon ? "pl-11 pr-4" : "px-4",
    textarea ? "max-h-96 min-h-[3.5rem]" : "",
    inputClassName,
  ].join(" ");

  let input;
  if (textarea) {
    input = (
      <textarea
        ref={inputRef}
        id={name}
        type={type}
        name={name}
        value={value || ""}
        rows={rows}
        onChange={onChange}
        placeholder={placeholder}
        className={resolvedInputClassName}
        disabled={disabled}
        {...rest}
      />
    );
  } else {
    input = (
      <input
        ref={inputRef}
        id={name}
        type={type}
        name={name}
        value={value || ""}
        onChange={onChange}
        placeholder={placeholder}
        className={resolvedInputClassName}
        disabled={disabled}
        {...rest}
      />
    );
  }

  return (
    <div className={`${className} grid gap-1`}>
      {label && <label htmlFor={name} className="text-base">{label}</label>}
      <div className="relative w-full">{input}</div>
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </div>
  );
};

InputField.propTypes = {
  inputRef: PropTypes.shape({ current: PropTypes.any }),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  icon: null,
  type: "text",
  value: "",
  onChange: () => {},
  placeholder: "",
  error: "",
  className: "",
  inputClassName: "",
  rows: 3,
  disabled: false,
};
