export const BasePage = ({ children, title }) => {
  return (
    <div className="bg-gray-50 h-screen flex flex-col">
      <div className="text-2xl px-8 py-5 border-b border-b-grey-300">
        {title}
      </div>
      {children}
    </div>
  );
};
