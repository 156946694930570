import { ToastContainer } from "react-toastify";
import { RouterProvider } from "react-router-dom";
import { router } from "@/router";

import "react-toastify/dist/ReactToastify.css";
import "spinkit/spinkit.min.css";

const App = () => {
  return (
    <div>
      {/* Router - used to navigate between pages */}
      <RouterProvider router={router} />

      {/* Toasts - used to display errors */}
      <ToastContainer
        position="bottom-left"
        closeButton={false}
        toastClassName="uli-toast"
        bodyClassName="uli-toast-body"
      />
    </div>
  );
};

export default App;
