import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import { Loader } from "@/components/Common/Loader";
import { CheckIcon } from "lucide-react";
import { cn } from "@/lib/utils";

const NotesEditor = ({
  name,
  value,
  onChange,
  placeholder,
  isSaving,
}) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'; // Reset the height.
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  }, [value]);

  const divOnClick = () => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }

  return (
    <div className="flex flex-col grow overflow-hidden cursor-text p-1 gap-2" onClick={divOnClick}>
      <textarea
        ref={textAreaRef}
        rows={1}
        className="text-sm overflow-auto focus:outline-0 resize-none w-full text-gray-700 peer"
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
      {!!value && <div className="text-gray-400 text-sm peer-focus:hidden">Click here to edit...</div>}
      <div className="items-center text-xs justify-end text-gray-400 gap-1 hidden peer-focus:flex">
        {isSaving ? "Saving..." : <><CheckIcon size={16} /> Saved</>}
      </div>
    </div>
  );
};

export const DocumentNotesPanel = ({
  className,
  notes,
  loadingNotes,
  updateNotes,
  updatingNotes,
}) => {
  const [notesInput, setNotesInput] = useState(notes);
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeout = useRef(null);

  // Set isTyping to true when user types and schedule a timeout to set it to false.
  const handleInputChange = (e) => {
    setNotesInput(e.target.value);
    setIsTyping(true);
    clearTimeout(typingTimeout.current);
    typingTimeout.current = setTimeout(() => setIsTyping(false), 1500);
  };

  // Only save when the user has stopped typing.
  useEffect(() => {
    if (!isTyping) {
      updateNotes({ notes: notesInput });
    }
  }, [notesInput, isTyping, updateNotes]);

  return (
    <div className={cn(className, "grow flex flex-col px-4 py-5 overflow-hidden")}>
      <div className={cn("grow overflow-hidden mb-6", "flex flex-col gap-4 justify-between")}>
        {loadingNotes && (
          <div className="flex flex-col justify-center h-full items-center gap-4">
            <Loader />
            Loading notes...
          </div>
        )}
        {!loadingNotes && (
          <NotesEditor
            name="notes"
            value={notesInput}
            onChange={handleInputChange}
            placeholder={"Click here to edit..."}
            isSaving={isTyping || updatingNotes}
          />
        )}
      </div>
    </div>
  );
};

DocumentNotesPanel.propTypes = {
  notes: PropTypes.any,
  loadingNotes: PropTypes.bool,
  updateNotes: PropTypes.func,
  updatingNotes: PropTypes.bool,
  alignBottom: PropTypes.bool,
};
