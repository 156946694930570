import PropTypes from "prop-types";
import { useState } from "react";
import { ChatView } from "@/components/Layout/ChatView";
import { MessageInput } from "@/components/Message/MessageInput";
import { Loader } from "@/components/Common/Loader";
import { cn } from "@/lib/utils";
import { FeedbackModal } from "@/components/Feedback/FeedbackModal";
import { FeedbackRating } from "@/model/FeedbackRating";
import { FeedbackTopic } from "@/model/FeedbackTopic";
import { QADto } from "@/model/QADto";

export const DocumentQAPanel = ({
  className,
  history,
  loadingHistory,
  askQuestion,
  alignBottom,
  getPredefinedComments,
  onSaveFeedback,
  viewingSourcesOfQaId,
  onViewSources,
}) => {
  const [messageInput, setMessageInput] = useState('');
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackModalData, setFeedbackModalData] = useState({});

  const onSendMessage = () => {
    if (!messageInput.trim()) return; 
    askQuestion({ question: messageInput });
    setMessageInput('');
  };

  const onFeedbackConfirmed = ({ predefinedComments, freeText }) => {
    const { qa, rating } = feedbackModalData;
    setShowFeedbackModal(false);
  
    onSaveFeedback({
      predefinedComments,
      freeText,
      qa,
      rating,
    });
  };

  const loadingResponse = !!history?.find(({ qa }) => qa._isOptimistic);
  const messageHistory = [];

  if (!loadingHistory) {
    messageHistory.push({
      message: "What would you like to know?",
      userSent: false,
      disableFeedback: true,
    });
  }

  if (history?.length > 0) {
    for (const { qa, sourceCount, userFeedback } of history) {
      messageHistory.push({
        id: qa.id,
        message: qa.question,
        userSent: true,
      });
      
      if (!qa._isOptimistic) {
        messageHistory.push({
          id: qa.id,
          message: qa.answer,
          userSent: false,
          userFeedback,
          onThumbsUp: () => {
            const rating = FeedbackRating.THUMBS_UP;
            setShowFeedbackModal(true);
            setFeedbackModalData({
              qa,
              rating,
              predefinedComments: getPredefinedComments({ 
                feedbackTopic: FeedbackTopic.QUERY_RESPONSE,
                rating,
              }),
            });
          },
          onThumbsDown: () => {
            const rating = FeedbackRating.THUMBS_DOWN;
            setShowFeedbackModal(true);
            setFeedbackModalData({
              qa,
              rating,
              predefinedComments: getPredefinedComments({
                feedbackTopic: FeedbackTopic.QUERY_RESPONSE,
                rating,
              }),
            });
          },
          sourceCount,
          showViewSourcesButton: true,
          currentlyViewingSources: viewingSourcesOfQaId === qa.id,
        });
      }
    }
  }

  const isPositiveRating = feedbackModalData.rating === FeedbackRating.THUMBS_UP;

  return (
    <div className={cn("grow flex flex-col p-6 overflow-hidden max-w-4xl", className)}>
      <div className={cn("grow overflow-auto mb-6", alignBottom && "flex flex-col justify-end")}>
        {loadingHistory && (
          <div className="flex flex-col justify-center h-full items-center gap-4">
            <Loader />
            Loading history...
          </div>
        )}
        {!loadingHistory && (
          <ChatView
            messageHistory={messageHistory}
            loading={loadingResponse}
            onViewSources={(message) => onViewSources(history.find(({ qa }) => qa.id === message.id).qa)}
          />
        )}
      </div>
      <MessageInput
        messageInput={messageInput}
        onInputChange={(e) => setMessageInput(e.target.value)}
        onSendMessage={onSendMessage}
        loading={loadingResponse}
      />
      <FeedbackModal
        open={showFeedbackModal}
        setOpen={setShowFeedbackModal}
        onConfirm={onFeedbackConfirmed}
        title={isPositiveRating
          ? "What did you like about the answer?" 
          : "What was the issue with the answer?"
        }
        predefinedComments={feedbackModalData.predefinedComments}
        additionalFeedbackPlaceholder={isPositiveRating
          ? "I liked the answer because..."
          : "The answer was not helpful because..."
        }
      />
    </div>
  )
};

DocumentQAPanel.propTypes = {
  className: PropTypes.string,
  history: PropTypes.arrayOf(PropTypes.instanceOf(QADto)),
  loadingHistory: PropTypes.bool,
  askQuestion: PropTypes.func.isRequired,
  alignBottom: PropTypes.bool,
  getPredefinedComments: PropTypes.func,
  onSaveFeedback: PropTypes.func,
  viewingSourcesOfQaId: PropTypes.string,
  onViewSources: PropTypes.func,
};
