import PropTypes from 'prop-types';
import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip';
import { useLocation, NavLink } from 'react-router-dom';

export const NavBarItem = ({ active, icon, tooltip, onClick }) => {
  const IconType = icon;
  const bgClass = active ? 'bg-primary-light' : '';
  return (
    <Tooltip delayDuration={200}>
      <TooltipTrigger asChild>
        <button
          className={`text-primary-light-3 hover:bg-primary-light rounded-lg p-2 ${bgClass}`}
          onClick={onClick}
        >
          <IconType size="28" />
        </button>
      </TooltipTrigger>
      <TooltipContent side="right">
        {tooltip}
      </TooltipContent>
    </Tooltip>
  );
};

NavBarItem.propTypes = {
  active: PropTypes.bool.isRequired,
  icon: PropTypes.elementType.isRequired,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export const NavBar = ({ navItems, bottomNavItems }) => {
  const { pathname } = useLocation();

  const renderNavLinks = (navItems) => (
    <>
      {navItems.map((navItem) => (
        <NavLink key={navItem.path} to={navItem.path}>
          <NavBarItem
            active={navItem.activePathRegex?.test(pathname) ?? pathname === navItem.path}
            {...navItem}
          />
        </NavLink>
      ))}
    </>
  );

  return (
    <div className="bg-primary-dark text-white px-6 py-6 flex flex-col basis-full items-center gap-12 w-max h-screen">
      <img src="/a2i2.svg" alt="Logo" />
      <div className="flex flex-col h-full">
        <div className="flex flex-col gap-8 grow">
          {renderNavLinks(navItems)}
        </div>
       <div className="flex flex-col gap-8">
        {renderNavLinks(bottomNavItems)}
       </div>
      </div>
    </div>
  )
};

const NavBarItemShape = PropTypes.shape({
  path: PropTypes.string.isRequired,
  activePathRegex: PropTypes.instanceOf(RegExp),
  icon: PropTypes.elementType.isRequired,
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func,
});

NavBar.propTypes = {
  navItems: PropTypes.arrayOf(NavBarItemShape).isRequired,
  bottomNavItems: PropTypes.arrayOf(NavBarItemShape),
};

NavBar.defaultProps = {
  bottomNavItems: [],
};
