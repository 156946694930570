import { useDropzone } from "react-dropzone";
import { ArrowUpTrayIcon, TrashIcon } from "@heroicons/react/24/outline";

const FileSelected = ({ file, setFile }) => {
  return (
    <div className="w-full flex justify-center border border-primary border-dashed p-8 bg-slate-100">
      <div className="flex flex-col items-center">
        <ArrowUpTrayIcon height={18} className="text-slate-400" />
        <span className="text-slate-600">{file.name}</span>
        <button className="text-red-600 text-sm flex gap-1 items-center" onClick={() => setFile(null)}>
          <TrashIcon className="inline-block" height={14} />
          Remove File
        </button>
      </div>
    </div>
  );
};

// TODO: Allow multiple files.
// TODO: Allow file validation.
const NoFileSelected = ({ label, setFile, setFiles, maxFiles, accept }) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles,
    onDrop: (acceptedFiles) => {
      setFile?.(acceptedFiles[0]);
      setFiles?.(acceptedFiles);
    },
    accept,
  });
  return (
    <button {...getRootProps()} className="w-full flex justify-center border border-primary border-dashed p-8 bg-primary-light-3 ring-0 outline-none">
      <div className="flex flex-col items-center">
        <ArrowUpTrayIcon height={18} className="text-primary" />
        <span className="text-primary text-base font-semibold">{ label }</span>
        <span className="text-primary-light text-sm font-medium">
          Click to browse or drag and drop to upload
        </span>
      </div>
      <input {...getInputProps()} />
    </button>
  )
};

export const Dropzone = ({ label, file, setFile, setFiles, maxFiles, accept }) => {
  return file 
    ? <FileSelected file={file} setFile={setFile} /> 
    : <NoFileSelected
        label={label}
        setFile={setFile}
        setFiles={setFiles}
        maxFiles={maxFiles}
        accept={accept}
      />;
};
