import PropTypes from "prop-types";
import { useState } from "react";
import { Objective } from "@/model/Objective";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from "@/components/Common/Tabs";
import { Button } from "@/components/Common/Button";
import { ShareIcon } from "lucide-react";
import { Badge } from "@/components/Common/Badge";
import { ReviewWorkflowPanelInbox } from "./ReviewWorkflowPanelInbox";
import { ReviewWorkflowPanelAccepted } from "./ReviewWorkflowPanelAccepted";
import { ReviewWorkflowPanelRejected } from "./ReviewWorkflowPanelRejected";
import { useDocumentCounts } from "@/hooks/useDocumentCounts";
import { ExportObjectiveDocumentsModal } from "./ExportObjectiveDocumentsModal";
import { ReviewWorkflowPanelIrrelevant } from "./ReviewWorkflowPanelIrrelevant";

const CountBadge = ({ count }) => {
  return (
    <Badge
      className="bg-gray-300 text-gray-600 group-data-[state=active]/trigger:bg-primary group-data-[state=active]/trigger:text-white"
    >
      {count}
    </Badge>
  );
};

export const ReviewWorkflowPanel = ({ selectedObjective, onShowUploadModal }) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const {
    pending,
    approved,
    rejected,
    irrelevant,
  } = useDocumentCounts({ objectiveId: selectedObjective?.id });

  return (
    <div className="grow overflow-hidden">
      <Tabs
        className="h-full flex flex-col"
        defaultValue="inbox"
        value={selectedTab}
        onValueChange={setSelectedTab}
        usePath
      >
        <div className="flex items-center bg-white border-b px-8">
          <TabsList>
            <TabsTrigger value="inbox" className="flex gap-2 group/trigger">
              Inbox
              <CountBadge count={pending} />
            </TabsTrigger>
            <TabsTrigger value="accepted" className="flex gap-2 group/trigger">
              Accepted
              <CountBadge count={approved} />
            </TabsTrigger>
            <TabsTrigger value="rejected" className="flex gap-2 group/trigger">
              Rejected
              <CountBadge count={rejected} />
            </TabsTrigger>
            <TabsTrigger value="irrelevant" className="flex gap-2 group/trigger">
              Unrelated
              <CountBadge count={irrelevant} />
            </TabsTrigger>
          </TabsList>
          {approved > 0 && (
            <Button
              label={<><ShareIcon size="16" /> Export</>}
              buttonStyle="success"
              buttonSize="sm"
              onClick={() => setShowExportModal(true)}
            />
          )}
        </div>
        <TabsContent value="inbox" className="h-full overflow-hidden">
          <ReviewWorkflowPanelInbox
            objective={selectedObjective}
            onShowUploadModal={onShowUploadModal}
          />
        </TabsContent>
        <TabsContent value="accepted" className="h-full overflow-hidden">
          <ReviewWorkflowPanelAccepted objective={selectedObjective} />
        </TabsContent>
        <TabsContent value="rejected" className="h-full overflow-hidden">
          <ReviewWorkflowPanelRejected objective={selectedObjective} />
        </TabsContent>
        <TabsContent value="irrelevant" className="h-full overflow-hidden">
          <ReviewWorkflowPanelIrrelevant objective={selectedObjective} />
        </TabsContent>
      </Tabs>
      <ExportObjectiveDocumentsModal
        objective={selectedObjective}
        open={showExportModal}
        setOpen={setShowExportModal}
      />
    </div>
  );
};

ReviewWorkflowPanel.propTypes = {
  selectedObjective: PropTypes.instanceOf(Objective).isRequired,
  onShowUploadModal: PropTypes.func.isRequired
};
