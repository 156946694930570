export class Document {
  constructor(data = {}) {
    this.id = data.id;
    this.filename = data.filename;
    this.summary = data.summary;
    this.summaryEmbedding = data.summary_embedding ?? data.summaryEmbedding;
    this.shasum = data.shasum;
    this.createdAt = new Date(data.created_at ?? data.createdAt);
    this.updatedAt = new Date(data.updated_at ?? data.updatedAt);
  }
}
