import PropTypes from 'prop-types';
import { Loader } from '@/components/Common/Loader';
import { DocumentWithRank } from '@/model/DocumentWithRank';
import { DocumentState } from '@/model/DocumentState';
import { FileIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useEffect } from 'react';
import { useDocuments } from '@/hooks/useDocuments';

const RankedDocumentListItem = ({ objectiveDocument, active, onClick }) => {
  return (
    <button
      className={cn(
        "flex gap-4 p-4",
        "bg-white hover:bg-primary-light-3",
        "cursor-pointer",
        "group/button",
        "data-[active=true]:bg-primary-light",
      )}
      data-active={active}
      onClick={onClick}
    >
      <div className="bg-[#6380B9] min-w-[24px] min-h-[24px] rounded grid place-content-center mt-[4px]"><FileIcon size={16} /></div>
      <div className="grow text-start">
        <div className={cn(
          "text-black font-medium text-sm",
          "group-data-[active=true]/button:text-white",
        )}>
          {objectiveDocument.documentName}
        </div>
        <div className={cn(
          "text-gray-600 text-xs",
          "group-data-[active=true]/button:text-gray-200",
          "line-clamp-2"
        )}>
          {objectiveDocument.document.summary}
        </div>
      </div>
    </button>
  );
};
RankedDocumentListItem.propTypes = {
  active: PropTypes.bool.isRequired,
  objectiveDocument: PropTypes.instanceOf(DocumentWithRank).isRequired,
  onClick: PropTypes.func.isRequired,
};

export const RankedDocumentList = ({
  objectiveId,
  documentState,
  selectedDocument,
  onDocumentSelected
}) => {
  const { documents, loading } = useDocuments({
    objectiveId,
    documentState
  });

  useEffect(() => {
    // Select a document if none is selected
    const hasDocuments = documents?.length > 0;
    if (!selectedDocument && hasDocuments) {
      onDocumentSelected(documents[0]);
    }

    // If the selected document is no longer in the list, select the first one
    // or clear the selection if there are no documents
    const documentExists = documents?.find(d => d.id === selectedDocument?.id);
    if (selectedDocument && !documentExists) {
      if (hasDocuments) {
        onDocumentSelected(documents[0]);
      } else {
        onDocumentSelected(null);
      }
    }
  }, [documents, selectedDocument, onDocumentSelected]);

  return (
    <div className="grid gap-2 grid-cols-1 text-white overflow-hidden">
      {loading && (
        <div className="flex flex-col gap-3 self-center">
          <div className="flex justify-center">
            <Loader />
          </div>
          <div className="flex justify-center text-black">
            Loading documents...
          </div>
        </div>
      )}

      {!loading && documents.length > 0 && (
        <>
          <div className="flex flex-col overflow-y-auto">
            {documents.map((document) => (
              <RankedDocumentListItem 
                key={document.id}
                active={selectedDocument?.id === document.id}
                objectiveDocument={document}
                onClick={() => onDocumentSelected(document)} />
            ))}
          </div>
        </>
      )}
    </div>
  )
};

RankedDocumentList.propTypes = {
  selectedDocument: PropTypes.instanceOf(DocumentWithRank),
  objectiveId: PropTypes.string.isRequired,
  documentState: PropTypes.oneOf(Object.values(DocumentState)).isRequired,
  onDocumentSelected: PropTypes.func.isRequired,
};

RankedDocumentList.defaultProps = {
  documentState: DocumentState.PENDING,
};