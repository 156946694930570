import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getObjectivesForProject, deleteObjective } from "@/modules/api";
import { Objective } from "@/model/Objective";
import { InputSelect } from "@/components/Common/InputSelect";
import { ObjectiveForm } from "./ObjectiveForm";
import { Button } from "@/components/Common/Button";
import { EditIcon, TrashIcon, PlusIcon } from 'lucide-react';
import { DeleteModal } from "@/components/Common/DeleteModal";

export const ObjectiveSelection = ({ projectId, label, selectedObjective, setSelectedObjective }) => {
  const [showForm, setShowForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editingObjective, setEditingObjective] = useState(null);
  const queryClient = useQueryClient();

  const { data: objectives, isFetching, refetch, } = useQuery({
    queryKey: ["objectives", projectId],
    queryFn: () => getObjectivesForProject({ projectId }),
    placeholderData: [],
  });

  const { mutateAsync: deleteObjectiveMutation } = useMutation({
    mutationFn: deleteObjective,
    onSuccess: (data, { objectiveId }) => {
      // Remove the objective from the cache
      queryClient.setQueryData(
        ["objectives", projectId],
        (oldData) => oldData.filter((o) => o.id !== objectiveId)
      );
    },
  });

  // If the selected objective is no longer in the list of objectives, clear it
  useEffect(() => {
    if (isFetching) return;
    if (selectedObjective && !objectives.find((o) => o.id === selectedObjective.id)) {
      setSelectedObjective(null);
    }
  }, [selectedObjective, objectives, setSelectedObjective, isFetching]);

  if (isFetching) return null;

  const onOptionSelected = (option) => {
    if (option.value === "new") {
      setShowForm(true);
    } else {
      setSelectedObjective(option.objective);
    }
  };

  const onEditSelected = () => {
    setEditingObjective(selectedObjective);
    setShowForm(true);
  };

  const onObjectiveSaved = async (objective) => {
    await refetch();
    setShowForm(false);
    setEditingObjective(null);
    setSelectedObjective(objective);
  };

  const onDeleteConfirmed = async () => {
    setShowDeleteModal(false);
    await deleteObjectiveMutation({ objectiveId: selectedObjective.id });
    setSelectedObjective(null);
  }

  const dropdownOptions = objectives.map((objective) => ({
    value: objective.id,
    label: objective.description,
    objective,
  }));

  dropdownOptions.push({
    value: "new",
    label: <div className="flex gap-1 items-center"><PlusIcon size={16} />Create new objective...</div>,
  });

  return (
    <div className="flex gap-2 items-center">
      {label && <div className="text-sm text-gray-500">{label}</div>}
      <InputSelect
        className="min-w-[250px] max-w-[350px]"
        placeholder="Select objective..."
        options={dropdownOptions}
        value={selectedObjective?.id}
        onChange={onOptionSelected}
      />
      {selectedObjective && (
        <>
          <Button label={<EditIcon size={16} />} buttonSize="sm" onClick={onEditSelected} />
          <Button label={<TrashIcon size={16} />} buttonSize="sm" onClick={() => setShowDeleteModal(true)} />
        </>
      )}
      <ObjectiveForm
        projectId={projectId}
        open={showForm}
        editObjective={editingObjective}
        onSaved={onObjectiveSaved}
        onBack={() => setShowForm(false)}
      />
      <DeleteModal
        open={showDeleteModal}
        onDelete={onDeleteConfirmed}
        onClose={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

ObjectiveSelection.propTypes = {
  label: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  selectedObjective: PropTypes.instanceOf(Objective),
  setSelectedObjective: PropTypes.func.isRequired,
};
