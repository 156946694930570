import PropTypes from "prop-types";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "lucide-react";
import { useRef, useState } from "react";

export const CollapsiblePanel = ({
  className,
  children,
  isCollapsed,
  setIsCollapsed,
  side,
  disabled
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverDetectorRef = useRef(null);

  // Detect when mouse is over just the hover detector.
  const handleMouseMove = (event) => {
    if (hoverDetectorRef.current) {
      const {
        top,
        right,
        bottom,
        left
      } = hoverDetectorRef.current.getBoundingClientRect();
      if (
        event.clientX >= left &&
        event.clientX <= right &&
        event.clientY >= top &&
        event.clientY <= bottom
      ) {
        setIsHovered(true);
      } else {
        setIsHovered(false);
      }
    }
  };

  // Toggle the panel when the collapse button is clicked.
  const handleClick = () => {
    setIsCollapsed(prevState => !prevState);
  };

  // Determine the classes to apply to the container based on the side.
  const sideClass = cva([], {
    variants:{
      side: {
        left: "left-0",
        right: "right-0",
      },
    },
    compoundVariants: [
      {
        side: ["left", "right"],
        className: "bottom-0 w-fit flex flex-col justify-center",
      },
      {
        side: "top",
        className: "left-0 right-0 flex justify-center",
      },
    ],
  })({ side });

  // Map the side & state to the appropriate icon.
  const sideToIcon = {
    left: {
      collapsed: ChevronLeftIcon,
      expanded: ChevronRightIcon,
    },
    right: {
      collapsed: ChevronRightIcon,
      expanded: ChevronLeftIcon,
    },
    top: {
      collapsed: ChevronUpIcon,
      expanded: ChevronDownIcon
    }
  };
  const Icon = sideToIcon[side][isCollapsed ? "collapsed" : "expanded"];

  return (
    <div
      className={cn("flex flex-col overflow-hidden relative group min-h-[45px]", className)}
      onMouseMove={handleMouseMove}
    >
      {/* Hover detector & collapse button */}
      {!disabled && (
        <div
          ref={hoverDetectorRef}
          className={cn(
            sideClass,
            "absolute",
            "top-0",
            "p-2", 
            "opacity-0",
            "z-10",
            "pointer-events-none",
            !isCollapsed && "invisible group-hover:visible",
            (isHovered || isCollapsed) && "opacity-100"
          )}
        >
          <button
            className={cn(
              "bg-white",
              "text-primary",
              "rounded-full",
              "p-1",
              "drop-shadow-lg",
              "pointer-events-auto",
            )}
            onClick={handleClick}
          >
            <Icon size={16} />
          </button>
        </div>
      )}

      {!isCollapsed && children}
    </div>
  );
};

CollapsiblePanel.propTypes = {
  children: PropTypes.node.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  setIsCollapsed: PropTypes.func.isRequired,
  side: PropTypes.oneOf(["left", "right", "top"]).isRequired,
  disabled: PropTypes.bool,
};

CollapsiblePanel.defaultProps = {
  side: "right",
};
