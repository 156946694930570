import { getDocumentsForObjective, updateObjectiveDocumentState, undoObjectiveDocumentStateUpdate } from "@/modules/api";
import { useCallback } from "react";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { DocumentState } from "@/model/DocumentState";
import { DocumentWithRank } from "@/model/DocumentWithRank";
import { DocumentCounts } from "@/model/DocumentCounts";
import { toast } from "react-toastify";
import { getDocumentCountsQueryKey } from "./useDocumentCounts";

export const getDocumentsQueryKey = (objectiveId, documentState) => documentState 
  ? [
    "documents",
    objectiveId,
    documentState,
  ]
  : [
    "documents",
    objectiveId,
  ];

export const useDocuments = ({ objectiveId, documentState }) => {
  const queryClient = useQueryClient();

  const { data: documents, isLoading: loading, refetch } = useQuery({
    queryFn: () => getDocumentsForObjective({ objectiveId, documentState }),
    queryKey: ['documents', objectiveId, documentState],
    enabled: !!objectiveId && !!documentState,
  });

  const { mutate } = useMutation({
    mutationFn: ({ objectiveDocument, documentState, comments }) => {
      return documentState === DocumentState.PENDING
        ? undoObjectiveDocumentStateUpdate({ objectiveDocumentId: objectiveDocument.id })
        : updateObjectiveDocumentState({
          objectiveDocumentId: objectiveDocument.id,
          documentState,
          comments,
        });
    },
    onMutate: async ({ objectiveDocument, documentState }) => {
      const prevQueryKey = getDocumentsQueryKey(objectiveId, objectiveDocument.documentState);
      const nextQueryKey = getDocumentsQueryKey(objectiveId, documentState);
      const countsQueryKey = getDocumentCountsQueryKey(objectiveId);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(prevQueryKey);
      await queryClient.cancelQueries(nextQueryKey);
      await queryClient.cancelQueries(countsQueryKey);

      // Optimistically remove the document from the previous categories cache.
      const prevQueryBackup = queryClient.getQueryData(prevQueryKey);
      if (prevQueryBackup) {
        queryClient.setQueryData(
          prevQueryKey,
          (existing) => existing?.filter(
            (document) => document.id !== objectiveDocument.id
          ),
        );
      }

      // Optimistically add the document to the next categories query cache.
      const nextQueryBackup = queryClient.getQueryData(nextQueryKey);
      if (nextQueryBackup) {
        queryClient.setQueryData(
          nextQueryKey,
          (existing) => [
            ...existing,
            new DocumentWithRank({ ...objectiveDocument, documentState })
          ],
        );
      }

      // Optimistically update the document counts query cache.
      const countsQueryBackup = queryClient.getQueryData(countsQueryKey);
      if (countsQueryBackup) {
        const prevCountKey = objectiveDocument.documentState.toLowerCase();
        const nextCountKey = documentState.toLowerCase();
        queryClient.setQueriesData(
          countsQueryKey,
          (existing) => new DocumentCounts({
            ...existing,
            [prevCountKey]: existing[prevCountKey] - 1,
            [nextCountKey]: existing[nextCountKey] + 1,
          }),
        )
      }

      return {
        prevQueryKey,
        prevQueryBackup,
        nextQueryKey,
        nextQueryBackup,
        countsQueryKey,
        countsQueryBackup,
      };
    },
    onError: (err, { objectiveDocument, documentState }, context) => {
      // Rollback to the previous value on error
      if (context?.prevQueryKey && context?.prevQueryBackup) {
        queryClient.setQueryData(context.prevQueryKey, context.prevQueryBackup);
      }
      if (context?.nextQueryKey && context?.nextQueryBackup) {
        queryClient.setQueryData(context.nextQueryKey, context.nextQueryBackup);
      }
      if (context?.countsQueryKey && context?.countsQueryBackup) {
        queryClient.setQueryData(context.countsQueryKey, context.countsQueryBackup);
      }

      console.error(err);
      toast.error(`Failed to complete action on document ${objectiveDocument.documentName}`);
    },
    onSuccess: (data, { objectiveDocument, documentState }, context) => {
      if (documentState === DocumentState.PENDING) {
        toast.info(`Successfully reset evaluation on document ${objectiveDocument.documentName}`);
      } else {
        toast.success(`Successfully ${documentState.toLowerCase()} document ${objectiveDocument.documentName}`);
      }
    },
    onSettled: (data, error, variables, context) => {
      // Refetch all document queries for the objective and the document counts query
      const allObjectiveDocumentsQueryKey = getDocumentsQueryKey(objectiveId);
      queryClient.invalidateQueries(allObjectiveDocumentsQueryKey);
      queryClient.invalidateQueries(context?.countsQueryKey);
    },
  });

  const approveDocument = useCallback((objectiveDocument) => mutate({
    objectiveDocument,
    documentState: DocumentState.APPROVED,
  }), [mutate]);

  const rejectDocument = useCallback((objectiveDocument, comments) => mutate({
    objectiveDocument,
    documentState: DocumentState.REJECTED,
    comments,
  }), [mutate]);

  const resetDocument = useCallback((objectiveDocument) => mutate({
    objectiveDocument,
    documentState: DocumentState.PENDING,
  }), [mutate]);

  return {
    loading,
    documents,
    refetch,
    approveDocument,
    rejectDocument,
    resetDocument,
  }
};
