import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { InputField } from "@/components/Common/InputField";
import { Button } from "@/components/Common/Button";
import { createObjective, updateObjective } from "@/modules/api";
import { Objective } from "@/model/Objective";
import { Modal } from "@/components/Common/Modal";

export const ObjectiveForm = ({ open, projectId, editObjective, onSaved, onBack }) => {
  const [objectiveDescription, setObjectiveDescription] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editObjective) {
      setObjectiveDescription(editObjective.description);
    } else {
      setObjectiveDescription('');
    }
  }, [editObjective]);

  const onSave = async () => {
    setLoading(true);
    let savedObjective;
    if (editObjective) {
      savedObjective = await updateObjective({
        objectiveId: editObjective.id,
        description: objectiveDescription,
      });
    } else {
      savedObjective = await createObjective({
        projectId,
        description: objectiveDescription,
      });
    }
    onSaved(savedObjective);
  };
  
  return (
    <Modal
      open={open}
      title={editObjective ? "Edit objective" : "Create new objective"}
      actionsSlot={
        <>
          <Button label="Cancel" onClick={onBack} disabled={loading} buttonStyle="outlined" />
          <Button label="Save" onClick={onSave} disabled={loading} buttonStyle="primary" />
        </>
      }
    >
      <InputField
        name="objectiveDescription"
        placeholder="Write your new objective..."
        value={objectiveDescription}
        onChange={(e) => setObjectiveDescription(e.target.value)}
        disabled={loading}
      />
    </Modal>
  )
};

ObjectiveForm.propTypes = {
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  editObjective: PropTypes.instanceOf(Objective),
  onSaved: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};
