import { useAuth } from "@/contexts/auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const GuardedRoute = ({ children }) => {
  const { loggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  }, [loggedIn, navigate]);

  return children;
};
